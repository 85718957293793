/** @format */

// Düzeltildi

import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Dialog,
  DialogContent,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import AddIcon from "@mui/icons-material/Add";
import { lighten, darken } from "polished";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { addTaxType } from "../../api/taxTypes/add";
import { removeTaxType } from "../../api/taxTypes/remove";
import { getTaxTypes } from "../../api/taxTypes/getList";
import { addAllTaxTypes } from "../../api/taxTypes/addDefaultList";
const TaxTypeModal = ({ setReload, reload, setOpenModal, openModal }) => {
  const [selectedTax, setSelectedTax] = useState([]);
  const [newTax, setNewTax] = useState("");
  const [history, setHistory] = useState([]);
  useEffect(() => {
    getTaxTypes()
      .then((resp) => setHistory(resp.data.data))
      .catch((err) =>
        Swal.fire({
          icon: "warning",
          title: err.response.data.message,
          confirmButtonText: "Tamam",
        })
      );
  }, [reload]);
  const columnDefs = [
    {
      field: "id",
      flex: 1,
      headerName: "ID",
      cellStyle: { textAlign: "start" },
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "value",
      flex: 1,
      headerName: "Vergi",
      cellStyle: { textAlign: "start" },
      headerCheckboxSelection: true,
    },
  ];
  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedTax = selectedNodes.map((node) => node.data.value);
    setSelectedTax(selectedTax[0]);
  };
  const handleAdd = () => {
    addTaxType(newTax)
      .then((resp) => {
        setReload(!reload);
        setNewTax("");
        Swal.fire({
          icon: "success",
          title: resp.data.message,
          confirmButtonText: "Tamam",
        });
      })
      .catch((err) => {
        setReload(!reload);
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          confirmButtonText: "Tamam",
        });
      });
  };
  const handleAddAll = () => {
    addAllTaxTypes()
      .then((resp) => {
        setReload(!reload);
        Swal.fire({
          icon: "success",
          title: resp.data.message,
          confirmButtonText: "Tamam",
        });
      })
      .catch((err) => {
        setReload(!reload);
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          confirmButtonText: "Tamam",
        });
      });
  };
  const handleDelete = () => {
    removeTaxType(selectedTax)
      .then((resp) => {
        setReload(!reload);
        setSelectedTax([]);
        Swal.fire({
          icon: "success",
          title: resp.data.message,
          confirmButtonText: "Tamam",
        });
      })
      .catch((err) => {
        setReload(!reload);
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          confirmButtonText: "Tamam",
        });
      });
  };
  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg">
      <DialogContent>
        <Grid container sx={{ height: "70vh", width: "fit-content" }}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign={"center"}>
              {" "}
              Vergi Türleri
            </Typography>{" "}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Eklemek istediğiniz vergi türü"
              onChange={(e) => setNewTax(e.target.value)}
              value={newTax}
              multiline={true}
              sx={{ marginTop: "2%" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "row",
              justifyContent: "center",
              margin: 2,
            }}
          >
            {" "}
            <Button
              onClick={() => handleAdd()}
              sx={{
                textTransform: "none",
                fontWeight: "700",
                color: lighten(0.1, "green"),
                backgroundColor: lighten(0.7, "green"),
                alignSelf: "center",
                "&:hover": {
                  backgroundColor: lighten(0.6, "green"),
                },
              }}
              variant="contained"
              startIcon={
                <AddIcon
                  sx={{
                    color: !newTax ? "gray" : lighten(0.1, "green"),
                  }}
                />
              }
              disabled={!newTax}
            >
              Ekle
            </Button>
            <Button
              onClick={() => handleAddAll()}
              sx={{
                textTransform: "none",
                fontWeight: "700",
                color: lighten(0.1, "green"),
                backgroundColor: lighten(0.5, "green"),
                alignSelf: "center",
                "&:hover": {
                  backgroundColor: lighten(0.4, "green"),
                },
              }}
              variant="contained"
              startIcon={
                <AddIcon
                  sx={{
                    color: lighten(0.1, "green"),
                  }}
                />
              }
            >
              Default vergileri otomatik ekle
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2%",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  color: "red",
                  backgroundColor: lighten(0.4, "red"),
                  marginLeft: "auto",
                  fontWeight: "700",
                  "&:hover": {
                    backgroundColor: lighten(0.3, "red"),
                    color: darken(0.1, "red"),
                  },
                }}
                onClick={() => handleDelete()}
                variant="contained"
                disabled={selectedTax?.length === 0}
                startIcon={
                  <DeleteIcon
                    sx={{
                      color: selectedTax?.length === 0 ? "gray" : "red",
                    }}
                  />
                }
              >
                Seçilen vergiyi sil
              </Button>
            </Grid>

            <div
              className="ag-theme-quartz"
              style={{
                flex: 1,
                minHeight: "10vh",
                width: "100%",
                marginBottom: "3%",
              }}
            >
              <AgGridReact
                style={{ flex: 1, width: "100%" }}
                rowData={history}
                columnDefs={columnDefs}
                gridOptions={{
                  suppressCellFocus: true,
                }}
                enableCellTextSelection={true}
                rowSelection={"single"}
                domLayout="autoHeight"
                rowMultiSelectWithClick={true}
                overlayNoRowsTemplate={"Gösterilecek vergi yok"}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TaxTypeModal;
