import React, { useState, useMemo, useCallback, useEffect } from "react";

import Sidebar from "../../components/sideBar/sideBar.js";
import Navbar from "../../components/navbar/navbar.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangePicker from "../../components/DateRangePicker/index.js";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";
import { FileInfo } from "tabler-icons-react";
import { createColumnDefs } from "./columnDefs.js";
import { getReportList } from "../../api/getReportList/";
import localStorage from "local-storage";
import { darken } from "polished";
import FilterDrawer from "../../components/FilterDrawer";
import moment from "moment";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import JSZip from "jszip";
import {
  Grid,
  Drawer,
  Typography,
  Pagination,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import localeTextTr from "../../locale.tr.js";

function DailyReport() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastScroll, setLastScroll] = useState();
  const today = moment().format("YYYY-MM-DD");
  const monthAgo = moment().subtract(30, "days").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(monthAgo);
  const [endDate, setEndDate] = useState(today);
  const [selectedApi, setSelectedApi] = useState(null);
  const [gridReady, setGridReady] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [opennDrawer, setOpennDrawer] = useState(false);
  const [filters, setFilters] = useState({
    status: null,
    reportType: [],
    taxDeclared: null,
    DeclarationStatus: null,
  });

  useEffect(() => {
    const lastPage = localStorage.get("page");
    const lastPageSize = localStorage.get("pageSize");
    const sideBarOpen = localStorage.get("sidebar");

    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    if (lastPage) {
      setCurrentPage(lastPage);
    }
    if (lastPageSize) {
      setPageSize(lastPageSize);
    }
    const localScroll = localStorage.get("scrollPosition");
    if (localScroll) {
      setLastScroll(localScroll);
    }
    search();
    const cleanupLocalStorage = () => {
      localStorage.clear();
    };
    window.addEventListener("beforeunload", cleanupLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", cleanupLocalStorage);
    };
  }, [pageSize, pageNumber]);
  function clearLocalStorage() {
    localStorage.clear();
  }
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    }),
    []
  );

  const search = () => {
    console.log("deialy", filters);
    getReportList(
      pageSize,
      pageNumber,
      startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      filters.reportType.length === 0
        ? []
        : filters.reportType === "Günlük Rapor"
        ? "dailyReport"
        : "taxPayersList"
    )
      .then((result) => {
        if (result.data.getReport.length > 0) {
          const newResult = result.data.getReport.map((report) => ({
            ...report,
            createdAt: moment(report.createdAt).format("YYYY-MM-DD HH:mm"),
            updatedAt: moment(report.updatedAt).format("YYYY-MM-DD HH:mm"),
          }));
          setRowData(newResult);
          setTotalPage(result.data.totalPage);
          setTotalCount(result.data.totalReportsCount);
        } else {
          setRowData([]);
        }
      })
      .catch((err) =>
        Swal.fire({
          text:
            err.response.data.message || "Mükkelefler alınırken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        })
      );
  };
  const handleDownloadSelectedReports = () => {
    if (selectedRows.length === 1) {
      const params = selectedRows[0];
      const base64String = params.data;
      const fileName = params.description.includes("Günlük")
        ? "Günlük Rapor.xlsx"
        : "Mükellef Listesi.xlsx";

      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, fileName);
    } else {
      const zip = new JSZip();
      selectedRows.forEach((params) => {
        const base64String = params.data;
        const fileName = params.description.includes("Günlük")
          ? "Günlük Rapor.xlsx"
          : "Mükellef Listesi.xlsx";
        const uniqueFileName = `${params.id}_${fileName}`;
        zip.file(uniqueFileName, base64String, { base64: true });
      });

      zip
        .generateAsync({ type: "blob" })
        .then((blob) => {
          saveAs(blob, "Raporlar.zip");
        })
        .catch((error) => {
          console.error("ZIP dosyası oluşturulurken hata oluştu:", error);
        });
    }

    console.log("selectedmı bakalım", gridReady);
    if (selectedApi) {
      selectedApi.deselectAll();
    }
    if (gridReady) {
      gridReady.deselectAll();
    }
  };

  const ButtonRenderer = (props) => {
    const handleClick = () => {
      console.log(props.data);
      handleOpen();
      localStorage.set("scrollPosition", Math.floor(window.scrollY));
      // Buraya tıklama durumunda yapılacak işlemi ekleyin
    };

    const handleClickButtonDownload = (params) => {
      const base64String = params.data.data;
      const linkSource = `data:application/vnd.ms-excel;base64,${base64String}`;
      const downloadLink = document.createElement("a");
      const fileName = params.data.description.includes("Günlük")
        ? "Günlük Rapor.xlsx"
        : "Mükellef Listesi.xlsx";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      console.log(selectedApi, "sadasd");
      if (selectedApi) {
        selectedApi.deselectAll();
      }
      if (gridReady) {
        gridReady.deselectAll();
      }
    };
    return (
      <Button onClick={handleClick}>
        <FileInfo></FileInfo>
      </Button>
    );
  };
  const handleClickButtonDownload = (params) => {
    if (!params || !params.data) {
      return;
    }

    const base64String = params.data.data;
    const linkSource = `data:application/vnd.ms-excel;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = params.data.description.includes("Günlük")
      ? "Günlük Rapor.xlsx"
      : "Mükellef Listesi.xlsx";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    if (selectedApi) {
      selectedApi.deselectAll();
    }
    if (gridReady) {
      gridReady.deselectAll();
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState(
    createColumnDefs(handleClickButtonDownload)
  );

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        defaultExpanded: false,
        // sideBar={gridSideBar},
        // rowSelection: "multiple",
        // suppressRowClickSelection: true,
        enableRangeSelection: true,
        // paginationAutoPageSize: true,
        columnDefs: [{ field: "description" }],
        defaultColDef: {
          flex: 3,
          maxWidth: 300,
          resizable: true,
          floatingFilter: true,
          suppressMenu: false, // Allow menu in detail grid columns
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.subDetails);
      },
    };
  }, []);

  const onGridReady = useCallback((params) => {
    setRowData(rowData);
  }, []);

  return (
    <Grid container>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease", // Düzeltilmiş transition
        }}
      >
        <Sidebar status={isOpen} toggleSidebar={toggleSidebar} />
      </Grid>

      <Grid
        item
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid
            className="grid-area"
            sx={{
              mb: 1,
            }}
          >
            <Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              ></Grid>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="tr"
                >
                  <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <DateRangePicker
                      setStartDate={setStartDate}
                      startDate={startDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      isLimitedRangeForMonth={true}
                    />

                    <Button
                      sx={{
                        textTransform: "none",
                        alignItems: "center",
                        gap: 1,
                        marginLeft: "1vw",

                        backgroundColor: "#00cfe8",
                        "&:hover": {
                          backgroundColor: darken(0.1, "#00cfe8"),
                          borderColor: "#d9534f",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      onClick={() => search()}
                    >
                      <SearchIcon color="white" />
                      Ara
                    </Button>
                    <Button
                      sx={{
                        textTransform: "none",
                        alignItems: "center",
                        marginLeft: "1vw",
                        backgroundColor: "#6c757d",
                        "&:hover": {
                          backgroundColor: darken(0.1, "#6c757d"),
                          borderColor: "#d9534f",
                        },
                      }}
                      variant="contained"
                      onClick={() => setOpennDrawer(true)}
                    >
                      <FilterAltIcon color="white" />
                      Filtrele
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      height: "40px",
                      display: "flex",
                      ml: 2,
                      justifyContent: "right",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none", // Sadece istediğiniz harflerin büyük olmasını sağlar
                      }}
                      startIcon={<DownloadIcon />}
                      onClick={handleDownloadSelectedReports}
                      disabled={selectedRows.length === 0}
                    >
                      Seçilenleri İndir
                    </Button>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className="ag-theme-quartz"
            style={{
              flex: 1,
              minHeight: "67vh",
              width: "100%",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "10px", // İstediğiniz değeri buraya ekleyin
              overflow: "hidden", // Border-radius'in düzgün görünmesi için
            }}
          >
            <AgGridReact
              localeText={localeTextTr}
              rowSelection="multiple"
              rowData={rowData}
              columnDefs={columnDefs}
              enableRangeSelection={true}
              sideBar={gridSideBar}
              copyHeadersToClipboard={true}
              defaultColDef={defaultColDef}
              masterDetail={true}
              detailCellRendererParams={detailCellRendererParams}
              onGridReady={onGridReady}
              onSelectionChanged={(event) => {
                const selectedNodes = event.api.getSelectedNodes();
                const selectedData = selectedNodes.map((node) => node.data);
                setSelectedRows(selectedData);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "center",
              display: rowData?.length === 0 ? "none" : "flex",
              margin: "2% 0 2% 0",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPage}
              showFirstButton
              showLastButton
              page={pageNumber}
              onChange={(event, value) => setPageNumber(value)}
            />
            <TextField
              select
              value={pageSize}
              size="small"
              sx={{
                display: rowData?.length === 0 ? "none" : "block",
              }}
              onChange={(e) => {
                setPageNumber(1);
                setPageSize(e.target.value);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </TextField>
            <Typography sx={{ position: "absolute", right: "1%" }}>
              Toplam Kayıt : {totalCount}
            </Typography>
          </Grid>

          <Grid sx={{ display: "flex", justifyContent: "end" }}>
            <Drawer
              open={opennDrawer}
              anchor="right"
              onClose={() => setOpennDrawer(false)}
            >
              <FilterDrawer
                page={"DailyReport"}
                filters={filters}
                setFilters={setFilters}
                handleFetchData={search}
              />
            </Drawer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DailyReport;
