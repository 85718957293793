import React, { useState } from "react";
import { cookies } from "../../utils/cookie";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import {
  Grid,
  Button,
  Checkbox,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login } from "../../api/auth/login/index";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleLogin = (event) => {
    login(email, password)
      .then((resp) => {
        cookies.set("jwt-access", resp.data.tokens.access.token);
        cookies.set("jwt-access-expires", resp.data.tokens.access.expires);
        cookies.set("jwt-refresh", resp.data.tokens.refresh.token);
        cookies.set("jwt-refresh-expires", resp.data.tokens.refresh.expires);
        const decodedToken = jwtDecode(resp.data.tokens.access.token);
        const role = decodedToken.role;
        if (role === "user") {
          navigate("/homepage");
        } else if (role === "admin") {
          navigate("/admin");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
        });
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
        padding: isMobile ? "2vh" : "5vh",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Grid
        item
        xs={12}
        md={8.5}
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: isMobile ? 4 : 0,
        }}
      >
        <Grid
          component="img"
          sx={{
            width: isMobile ? "80%" : "65vh",
            height: "auto",
            maxHeight: isMobile ? "30vh" : "45vh",
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          alt="My Image"
          src={`/images/login-bg.png`}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={3.5}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ffffff",
          padding: "2vh",
          width: isMobile ? "100%" : "auto",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <img
              src="images/rahatbeyan.png"
              style={{
                objectFit: "cover",
                width: isMobile ? "90%" : "70%",
                marginBottom: "0.5em",
              }}
              alt="Logo"
            />
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "red",
              fontWeight: "700",
              display: "flex",
              justifyContent: "flex-end",
              mr: isMobile ? 0 : "4em",
            }}
          >
            <img
              src="https://servicesuser.rahatsistem.com.tr/images/products/rahatsistem/rs-logo/by-rs/1x/by-rahatsistem-logo.png"
              style={{
                objectFit: "cover",
                width: "20%",
                marginBottom: "0.5em",
                marginRight: "2%",
              }}
              alt="Logo"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <TextField
            className="input-field"
            fullWidth
            label="Email"
            onKeyDown={handleKeyDown}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <TextField
            fullWidth
            className="input-field"
            label="Şifre"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onKeyDown={handleKeyDown}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? (
                      <Visibility
                        fontSize="inherit"
                        style={{ fontSize: "1rem" }}
                      />
                    ) : (
                      <VisibilityOff
                        fontSize="inherit"
                        style={{ fontSize: "1rem" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-start",
              mb: isMobile ? 1 : 0,
            }}
          >
            <Checkbox
              checked={rememberMe}
              className="default-checked"
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <Typography variant="body2">Beni Hatırla</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: isMobile ? "flex-start" : "flex-end",
            }}
          >
            <Button
              onClick={() => navigate("/forgot-password")}
              className="unframed-button"
              sx={{
                color: "#786af2",
                textDecoration: "none",
                pl: isMobile ? 0 : undefined,
              }}
            >
              Şifreni mi unuttun?
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleLogin}
            className="custom-button"
          >
            Giriş
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography variant="body2">Henüz kaydınız yok mu? </Typography>
          <Button
            variant="body2"
            onClick={() => navigate("/register")}
            className="unframed-button"
            sx={{ color: "#786af2", textDecoration: "none" }}
          >
            Kayıt ol
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
