import { useState, useEffect } from "react";
import {
  Grid,
  Avatar,
  Typography,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Tooltip,
  Box,
} from "@mui/material";
import localStorage from "local-storage";
import Sidebar from "../../components/sideBar/sideBar";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import UserInfo from "./userProfileInfoPage.js";
import UserSetting from "./userProfileSettingsPage.js";
import { getUser } from "../../api/user/getUser";
import Swal from "sweetalert2";
import moment from "moment";

const ProfilePage = () => {
  const [data, setData] = useState({
    mailTitle: "",
    title: "",
    email: "",
    number: [],
    ivd_password: "",
    vkn: "",
    created_at: "",
    status: "",
    reportRange: "20-29",
  });

  useEffect(() => {
    getUser()
      .then((resp) => {
        console.log(resp.data);
        setData(resp.data);
      })
      .catch((err) =>
        Swal.fire({
          text:
            err.response.data.message ||
            "Müşavir bilgileri alınırken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        })
      );
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [selectedButtonId, setSelectedButtonId] = useState(1);

  const handleButtonClick = (buttonId) => {
    setSelectedButtonId(buttonId);
  };
  useEffect(() => {
    const sideBarOpen = localStorage.get("sidebar");

    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }

    const cleanupLocalStorage = () => {
      localStorage.clear();
    };
    window.addEventListener("beforeunload", cleanupLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", cleanupLocalStorage);
    };
  }, []);
  return (
    <Grid container>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease", // Düzeltilmiş transition
        }}
      >
        <Sidebar status={isOpen} toggleSidebar={toggleSidebar} />
      </Grid>

      <Grid
        item
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid //Profil Header Alanı
          item
          md={12}
          sx={{
            marginTop: "3vh",
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <Card sx={{ width: "100%", height: "101%" }}>
            <Grid
              sx={{
                height: 150,
                background: "linear-gradient(to right, #d3cce3, #e9e4f0);",
                position: "relative", // Logo yerleşimi için gerekli
              }}
            >
              {/* Logo bileşeni */}
              <img
                src="images/rahatbeyan.png" // Logonuzun yolu
                alt="Logo"
                style={{
                  position: "absolute", // Üstüne yerleşim
                  top: "50%", // Dikeyde ortalama
                  left: "50%", // Yatayda ortalama
                  transform: "translate(-50%, -50%)", // Ortalamayı ayarlamak için
                  width: "400px", // Logonun genişliği
                  height: "90px", // Logonun yüksekliği
                }}
              />
            </Grid>
            <CardContent sx={{ position: "relative" }}>
              <Avatar
                alt={data.title}
                src="/path-to-profile-image.jpg"
                sx={{
                  width: 120,
                  height: 120,
                  border: "4px solid white",
                  position: "absolute",
                  top: -60,
                  left: 20,
                  fontSize: 30,
                }}
              >
                {data.title
                  .split(" ") // Kelimeleri ayır
                  .map((word) => word[0]) // Her kelimenin ilk harfini al
                  .join("")}
              </Avatar>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  position: "relative",
                  top: { md: 5, xs: 0 },
                  left: 140,
                }}
              >
                <Grid item sx={{}}>
                  <Tooltip title={data.title}>
                    <Typography variant="h6" component="div">
                      {data.title.split(" ").slice(0, 3).join(" ") +
                        (data.title.split(" ").length > 3 ? "..." : "")}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  position: "absolute",
                  top: { md: 20, xs: 30 },
                  right: 20,
                }}
              >
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip
                    title={`Hesap oluşturulma tarihi: ${moment(
                      data.created_at
                    ).format("DD-MM-YYYY")}`}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CalendarTodayIcon fontSize="small" sx={{ mr: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                        {moment(data.created_at).format("DD-MM-YYYY")}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "5vh" }}
                />
                <Grid
                  item
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Üyelik Durumu :
                  </Typography>
                  <Chip
                    label={data.status ? "Aktif" : "Pasif"}
                    color={data.status ? "success" : "error"}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          md={12}
          sx={{
            display: "flex",
            mt: "3vh",
            gap: 5,
            pt: "1vh",
            pb: "1vh",
            alignItems: "center",
          }}
        >
          <Grid item md={1}>
            <Button
              fullWidth
              variant={selectedButtonId === 1 ? "contained" : "text"}
              type="submit"
              className={
                selectedButtonId === 1 ? "selected-button" : "unselected-button"
              }
              sx={{ display: "flex", gap: 1 }}
              onClick={() => handleButtonClick(1)}
            >
              <BadgeOutlinedIcon fontSize="medium" />
              Bilgiler
            </Button>
          </Grid>
          <Grid item md={1}>
            <Button
              fullWidth
              variant={selectedButtonId === 2 ? "contained" : "text"}
              type="submit"
              className={
                selectedButtonId === 2 ? "selected-button" : "unselected-button"
              }
              sx={{ display: "flex", gap: 1, pt: "1vh", pb: "1vh" }}
              onClick={() => handleButtonClick(2)}
            >
              <ManageAccountsOutlinedIcon fontSize="medium" />
              Ayarlar
            </Button>
          </Grid>
        </Grid>
        {selectedButtonId === 1 ? (
          <UserInfo userInfo={data} />
        ) : selectedButtonId === 2 ? (
          <UserSetting userInfo={data} />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default ProfilePage;
