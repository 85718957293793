/** @format */

import React from "react";
import { Grid } from "@mui/material";

const ShowPdfModal = ({ base64 }) => {
  return (
    <Grid container justifyContent="center" sx={{ overflow: "hidden" }}>
      <iframe
        style={{ width: "50vw", height: "80vh" }}
        src={`data:application/pdf;base64,${base64}`}
      />
    </Grid>
  );
};

export default ShowPdfModal;
