import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Grid, Typography, Container, Button } from "@mui/material";
import Login from "./pages/loginPage/loginPage.js";
import ForgotPassword from "./pages/forgotPassword/forgotPassword.js";
import Register from "./pages/registerPage/register.js";
import HomePage from "./pages/homePage/homePage.js";
import GridPage from "./pages/gridPage/gridPage.js";
import DailyReport from "./pages/dailyReportPage/dailyReport.js";
import ProfilePage from "./pages/profilePage/userProfile.js";
import AdminPage from "./pages/adminPage/adminPage.js";
import SenkDeneme from "./pages/synchPage/senkDeneme.js";
import AdminUsers from "./pages/adminPage/adminUsers.js";
import AdminSettings from "./pages/adminPage/adminProfilePage.js";
import AccrualsPage from "./pages/AccrualsPage/index.js";
import TaxPayerPage from "./pages/taxPayerPage/TaxPayerPage.js";
import LandingHome from "./pages/landingPage/index.js";
import "./App.css";
import { jwtDecode } from "jwt-decode";
import { cookies } from "./utils/cookie";
import SaklamaLanding from "./pages/saklamaLanding/index.js";
import NotificationPages from "./pages/NotificationPages/index.js";
import TaxPayerNotificationPage from "./pages/taxPayerPageNotification/TaxPayerNotificationPage.js";

const NotFound = () => {
  return (
    <Container
      maxWidth={false}
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ textAlign: "center" }}
      >
        <Grid item xs={12} sx={{ gap: 1 }}>
          <div className="four_zero_four_bg">
            <Typography
              variant="h1"
              style={{
                marginBottom: "20px",
                fontFamily:
                  '"Comic Sans MS", "Arial Rounded MT Bold", sans-serif',
              }}
            >
              404
            </Typography>
          </div>

          <div className="contant_box_404" style={{ gap: 1 }}>
            <Typography
              variant="h5"
              fontWeight={550}
              sx={{
                mb: 3,
                fontFamily:
                  '"Comic Sans MS", "Arial Rounded MT Bold", sans-serif',
              }}
            >
              Kaybolmuş gibi görünüyorsunuz
            </Typography>
            <Typography
              style={{
                marginBottom: "20px",
                fontFamily:
                  '"Comic Sans MS", "Arial Rounded MT Bold", sans-serif',
              }}
            >
              Aradığınız sayfa bulunamadı!
            </Typography>

            <Button
              href="https://panel.rahatbeyan.com/"
              variant="contained"
              sx={{
                marginBottom: 2,
                textDecoration: "underline",
                color: "#fff",
                backgroundColor: "#39ac31",
                "&:hover": {
                  backgroundColor: "#308f2a",
                  textDecoration: "underline",
                },
              }}
            >
              Geri Dön
            </Button>
            <Button
              href="https://panel.rahatbeyan.com/login"
              variant="outlined"
              sx={{
                borderColor: "#041737",
                color: "black",
                "&:hover": {
                  borderColor: "#041737",
                  backgroundColor: "#c5dafdc7",
                },
              }}
            >
              Ana Sayfaya Git
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const jwtToken = cookies.get("jwt-access");

      if (
        window.location.hostname === process.env.REACT_APP_LANDING_PAGE_DOMAIN
      ) {
        navigate("/");
      } else if (window.location.hostname === "rahatsaklama.com") {
        if (location.pathname === "/") {
          navigate("/");
        }
      } else {
        if (jwtToken) {
          const decodedToken = jwtDecode(jwtToken);
          const userRole = decodedToken.role;

          if (location.pathname === "/") {
            navigate("/homepage");
            return;
          }

          if (userRole === "admin" && !location.pathname.startsWith("/admin")) {
            navigate("/admin");
          } else if (
            userRole === "user" &&
            location.pathname.startsWith("/admin")
          ) {
            navigate("/NotFound");
          } else if (
            ["/register", "/forgot-password", "/login"].includes(
              location.pathname
            )
          ) {
            navigate("/homepage");
          }
        } else {
          if (
            !["/register", "/forgot-password", "/login"].includes(
              location.pathname
            )
          ) {
            navigate("/login");
          }
        }
      }
      setIsLoading(false);
    };

    checkAuth();
  }, [navigate, location]);

  // Başlık güncelleme
  useEffect(() => {
    if (window.location.hostname === "rahatsaklama.com") {
      document.title = "Rahat Saklama";
    } else {
      document.title = "Rahat Beyan";
    }
  }, [location]);

  if (isLoading) {
    return <div>...</div>; // veya bir yükleme spinner'ı
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          window.location.hostname ===
          process.env.REACT_APP_LANDING_PAGE_DOMAIN ? (
            <LandingHome />
          ) : window.location.hostname === "rahatsaklama.com" ? (
            <SaklamaLanding />
          ) : (
            <Navigate to="/login" replace /> // varsayılan yönlendirme
          )
        }
      />

      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/register" element={<Register />} />
      <Route path="/tahakkuklar" element={<AccrualsPage />} />
      <Route path="/tebligatlar" element={<NotificationPages />} />
      <Route path="/mukellef/:id" element={<TaxPayerPage />} />
      <Route path="/mukellef/tebligat/:id" element={<TaxPayerNotificationPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/homepage" element={<HomePage />} />
      <Route path="/gridPage" element={<GridPage />} />
      <Route path="/Senkronizasyon" element={<SenkDeneme />} />
      <Route path="/Raporlar" element={<DailyReport />} />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/admin/settings" element={<AdminSettings />} />
      <Route path="/admin/users" element={<AdminUsers />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
