import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Divider,
  Tooltip,
  Switch,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { updateUser } from "../../api/user/updateUser";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { PatternFormat } from "react-number-format";

const UserProfileSettingsPage = ({ userInfo }) => {
  //teb=tebligat
  const [automaticTebChecked, setAutomaticTebChecked] = useState();
  const [automaticSendTebChecked, setAutomaticSendTebChecked] = useState();
  const [selectedTebOption, setSelectedTebOption] = useState("");
  const [data, setData] = useState({
    mailTitle: "",
    title: "",
    email: "",
    number: [],
    ivd_password: "",
    vkn: "",
    created_at: "",
    status: "",
    reportRange: "20-29",
  });

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setData(userInfo);
  }, []);

  const handleChange = (e) => {
    if (e.target.name.includes("tckn") || e.target.name.includes("code")) {
      console.log(e.target.value, "asdas", e.target.name);
      setData((prevData) => {
        const newData = { ...prevData };

        if (!newData.ivd_credentials) {
          newData.ivd_credentials = {};
          console.log("Değer Değişti");
        }

        newData.ivd_credentials[e.target.name] = e.target.value;

        return newData;
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const checkIsValid = (e) => {
    // Kontrol etmek istediğiniz koşulları buraya ekleyin
    const [startDay, endDay] = e.target.value.split("-").map(Number);

    if (
      e.target.value.includes("-") &&
      startDay >= 1 &&
      endDay <= 31 &&
      startDay < endDay
    ) {
      setShowAlert(false);
    } else {
      // Koşullar sağlanmazsa hata mesajı göster
      setShowAlert(true);
    }
  };

  const handleSave = () => {
    console.log(data);
    const {
      mailTitle,
      vkn,
      ivd_password,
      number,
      title,
      ivd_credentials,
      reportRange,
    } = data;
    const newDataToSend = {
      mailTitle,
      vkn,
      ivd_password,
      number: number.map((num) => num.replace(/\s/g, "")),
      title,
      ivd_credentials: ivd_credentials || {},
      reportRange,
    };

    if (showAlert) {
      Swal.fire({
        text: "Hatalı tarih aralığı. Lütfen geçerli bir tarih aralığı girin.",
        icon: "error",
        confirmButtonText: "Tamam",
        customClass: {
          container: ".swal-overlay",
        },
      });
      return;
    }

    Swal.fire({
      text: "Müşavir bilgileri güncellenecek.Devam etmek istiyor musunuz?",
      icon: "question",
      showDenyButton: true,
      denyButtonText: "Hayır, devam etme",
      confirmButtonText: "Evet, devam et",
      customClass: { htmlContainer: ".swal2-container" },
    }).then((result) => {
      if (result.isConfirmed) {
        updateUser(newDataToSend)
          .then((resp) => {
            Swal.fire({
              text: resp.data?.message,
              icon: "success",
              confirmButtonText: "Tamam",
            });
          })
          .catch((err) =>
            Swal.fire({
              text: err.response.data?.message,
              icon: "error",
              confirmButtonText: "Tamam",
            })
          );
      }
    });
  };
  const handleAddField = (fieldName) => {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: [...prevData[fieldName], ""],
    }));
  };

  const handleRemoveField = (fieldName, index) => {
    setData((prevData) => {
      const updatedValues = [...prevData[fieldName]];
      updatedValues.splice(index, 1);
      return {
        ...prevData,
        [fieldName]: updatedValues,
      };
    });
  };

  const handleChangeField = (fieldName, index, value) => {
    setData((prevData) => {
      const updatedValues = [...prevData[fieldName]];
      updatedValues[index] = value;
      return {
        ...prevData,
        [fieldName]: updatedValues,
      };
    });
  };

  const PatternFormatCustom = React.forwardRef(function PatternFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        key={props.key}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="### ### ## ##"
      />
    );
  });

  return (
    <Grid
      container
      item
      md={12}
      className="grid-area"
      justifyContent={"space-between"}
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <TextField
          fullWidth
          label="Ünvan"
          variant="outlined"
          defaultValue={userInfo.title}
          onChange={(e) => handleChange(e)}
          inputProps={{ name: "title" }}
          sx={{
            "& .MuiOutlinedInput-root": { height: "50px", borderRadius: "8px" },
            "& .MuiInputLabel-root": { fontSize: "15px" },
            "& .MuiOutlinedInput-input": { padding: "12px", fontSize: "16px" },
          }}
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          defaultValue={userInfo.email}
          InputProps={{ readOnly: true, name: "email" }}
          sx={{
            "& .MuiOutlinedInput-root": { height: "50px", borderRadius: "8px" },
            "& .MuiInputLabel-root": { fontSize: "15px" },
            "& .MuiOutlinedInput-input": { padding: "12px", fontSize: "16px" },
          }}
        />
        <TextField
          fullWidth
          label="TC Kimlik No"
          variant="outlined"
          defaultValue={userInfo.ivd_credentials?.tckn || ""}
          onChange={handleChange}
          inputProps={{ name: "tckn" }}
          sx={{
            "& .MuiOutlinedInput-root": { height: "50px", borderRadius: "8px" },
            "& .MuiInputLabel-root": { fontSize: "15px" },
            "& .MuiOutlinedInput-input": { padding: "12px", fontSize: "16px" },
          }}
        />
        <TextField
          fullWidth
          label="VKN"
          variant="outlined"
          defaultValue={userInfo.vkn}
          onChange={handleChange}
          inputProps={{ name: "vkn" }}
          sx={{
            "& .MuiOutlinedInput-root": { height: "50px", borderRadius: "8px" },
            "& .MuiInputLabel-root": { fontSize: "15px" },
            "& .MuiOutlinedInput-input": { padding: "12px", fontSize: "16px" },
          }}
        />
        <TextField
          fullWidth
          label="Dijital Vergi Dairesi Kodu"
          variant="outlined"
          defaultValue={userInfo.ivd_credentials?.ivd_code || ""}
          onChange={handleChange}
          inputProps={{ name: "ivd_code" }}
          sx={{
            "& .MuiOutlinedInput-root": { height: "50px", borderRadius: "8px" },
            "& .MuiInputLabel-root": { fontSize: "15px" },
            "& .MuiOutlinedInput-input": { padding: "12px", fontSize: "16px" },
          }}
        />
        <TextField
          fullWidth
          label="Dijital Vergi Dairesi Şifresi"
          variant="outlined"
          defaultValue={userInfo.ivd_password || ""}
          onChange={handleChange}
          inputProps={{ name: "ivd_password" }}
          sx={{
            "& .MuiOutlinedInput-root": { height: "50px", borderRadius: "8px" },
            "& .MuiInputLabel-root": { fontSize: "15px" },
            "& .MuiOutlinedInput-input": { padding: "12px", fontSize: "16px" },
          }}
        />
      </Grid>

      <Divider
        orientation="vertical"
        flexItem
        sx={{ display: { xs: "none", md: "block" } }}
      />
      <Grid
        item
        xs={12}
        md={5.5}
        sx={{ display: "flex", gap: 2, flexDirection: "column" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Tooltip title="Seçtiğiniz tarihler (dahil) arasında rapor gönderilir.">
            <TextField
              fullWidth
              label="Günlük rapor gönderme tarih aralığı (gün)"
              value={data?.reportRange}
              name="reportRange"
              disabled={data.reportRange === "0-0"}
              onChange={(e) => {
                handleChange(e);
                checkIsValid(e);
              }}
              error={showAlert}
              helperText={
                showAlert
                  ? "Lütfen geçerli bir tarih aralığı girin.(örn.20-31)"
                  : ""
              }
              inputProps={{ maxLength: 5 }}
              sx={{ width: "83%" }}
            />
            <Switch
              checked={data.reportRange !== "0-0"}
              onChange={(e) => {
                if (e.target.checked) {
                  setData({ ...data, reportRange: "20-29" });
                } else {
                  setData({ ...data, reportRange: "0-0" });
                }
              }}
            ></Switch>
          </Tooltip>
          <Tooltip title="E-posta gönderen ismi.">
            <TextField
              fullWidth
              label="E-posta ve Whatsapp mesaj başlığı"
              value={data?.mailTitle}
              name="mailTitle"
              onChange={handleChange}
            />
          </Tooltip>

          {/* Telefon Numaraları */}
          <Typography sx={{ textAlign: "start" }}>
            {data?.number?.length < 2
              ? "Telefon numarası:"
              : "Telefon numaraları:"}
          </Typography>
          {data?.number?.map((number, index) => (
            <div key={index} style={{ display: "flex", gap: 1 }}>
              <TextField
                fullWidth
                value={number
                  .toString()
                  .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4")}
                placeholder="5## ### ## ##"
                label={`Numara ${index + 1}`}
                onBlur={(e) =>
                  handleChangeField("number", index, e.target.value)
                }
                InputProps={{ inputComponent: PatternFormatCustom }}
                sx={{ flex: 1 }}
              />
              <IconButton
                onClick={() => handleRemoveField("number", index)}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Tooltip title="Numara eklemek için tıklayın">
            <IconButton onClick={() => handleAddField("number")} size="small">
              <AddIcon />
            </IconButton>
          </Tooltip>

          <div sx={{ display: "flex", gap: 0, backgroundColor: "red" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={automaticTebChecked}
                  onChange={(event) =>
                    setAutomaticTebChecked(event.target.checked)
                  }
                  color="primary"
                />
              }
              label="e-Tebligatları günlük otomatik çek"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={automaticSendTebChecked}
                  onChange={(event) =>
                    setAutomaticSendTebChecked(event.target.checked)
                  }
                  color="primary"
                />
              }
              label="Çekilen e-Tebligatları müşavire otomatik gönder"
            />
            {automaticSendTebChecked && (
              <FormControl fullWidth>
                <Typography>Gönderim Seçeneği</Typography>
                <Select
                  value={selectedTebOption}
                  onChange={(event) =>
                    setSelectedTebOption(event.target.value || "")
                  }
                >
                  <MenuItem value="mail">Mail'e Gönder</MenuItem>
                  <MenuItem value="whatsapp">Whatsapp'a Gönder</MenuItem>
                  <MenuItem value="mailAndWhatsapp">
                    Mail ve Whatsapp'a gönder
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          mt: "3vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid md={3}>
          <Button
            variant="contained"
            color="primary"
            className="custom-button"
            onClick={() => handleSave()}
          >
            Değişiklikleri Kaydet
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserProfileSettingsPage;
