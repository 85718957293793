/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const getTaxTypes = async () => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }

    return await axios.get(`${process.env.REACT_APP_API_URL}/tax-type/list`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
