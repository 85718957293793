import {
  Grid,
  Typography,
  Tooltip,
  Chip,
  Button,
  TextField,
  MenuItem,
  Pagination,
  Drawer,
} from "@mui/material";
import date from "date-and-time";
import { darken, lighten } from "polished";

export const createColumnDefs = (setSelectedUser, setOpenUserDetailModal) => [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    cellStyle: {
      color: "black",
      fontWeight: "700",
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
    cellRenderer: (params) => (
      <Grid
        item
        sx={{
          gap: 2,
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setSelectedUser(params.data);
            setOpenUserDetailModal(true);
          }}
          sx={{ textTransform: "none" }}
        >
          Detay
        </Button>
        <Typography>ID:{params.data.id} </Typography>
      </Grid>
    ),
  },
  {
    field: "email",
    flex: 1,
    headerName: "Mail",
    cellStyle: {
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
  },
  {
    field: "title",
    flex: 1,
    headerName: "Ünvan",
    cellStyle: {
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
  },
  {
    field: "number",
    flex: 1,
    headerName: "Telefon",
    cellStyle: {
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
  },
  {
    field: "vkn",
    flex: 1,
    headerName: "VKN",
    cellStyle: {
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
  },
  {
    field: "ivd_credentials",
    valueGetter: (params) =>
      params.data.ivd_credentials ? params.data.ivd_credentials.tckn : "",
    flex: 1,
    headerName: "TCKN",
    cellStyle: {
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
  },
  {
    field: "totalTaxPayer",
    flex: 1,
    headerName: "Mükellef sayısı",
    cellRenderer: (props) => (
      <Tooltip title="Toplam mükellef sayısı">
        <Typography sx={{ fontSize: "14px" }}>
          {props.data.totalTaxPayer === 0
            ? "Mükellef Yok"
            : props.data.totalTaxPayer + " Adet"}
        </Typography>
      </Tooltip>
    ),
    cellStyle: {
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
  },
  {
    field: "created_at",
    flex: 1,
    headerName: "Oluşturulma Zamanı",
    cellRenderer: (props) => (
      <Tooltip title="İnternet vergi dairesine yüklenme zamanı">
        <Typography sx={{ fontSize: "14px" }}>
          {date.format(new Date(props.data.created_at), "YYYY-MM-DD HH:mm:ss")}
        </Typography>
      </Tooltip>
    ),
    cellStyle: {
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
  },
  {
    field: "status",
    flex: 1,
    headerName: "Durum",
    cellStyle: {
      textAlign: "start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    suppressMenu: true,
    cellRenderer: (props) => (
      <Chip
        size="small"
        sx={{
          backgroundColor: props?.data?.status
            ? lighten(0.6, "green")
            : lighten(0.4, "red"),
          color: props?.data?.status ? "green" : lighten(0.1, "red"),
        }}
        label={props?.data.status === true ? "Aktif" : "Pasif"}
      />
    ),
  },

  {
    flex: 0.5,
    field: "role",
    headerName: "Rol",
    suppressMenu: true,
  },
];
