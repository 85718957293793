
import axios from "axios";
import CheckToken from "../../../src/utils/checkToken/CheckToken";
export const stopSync = async (
   id
) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    return await axios.get(
        `${process.env.REACT_APP_API_URL}/sync/down-sync?syncId=${id}`,
        {
           
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
