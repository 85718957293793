/** @format */

import {
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  Alert,
  Box,
  Autocomplete,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState, useLayoutEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { darken, lighten } from "polished";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { getTaxTypes } from "../../api/taxTypes/getList";
import Swal from "sweetalert2";
import localStorage from "local-storage";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/tr"; // Türkçe dilini yükle
import { filter } from "jszip";
moment.locale("tr");
const FilterDrawer = ({ filters, setFilters, page, handleFetchData }) => {
  const [types, setTypes] = useState([]);
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(false);
  const [isDeclarationTypes, setİsDeclarationTypes] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const months = [
    { label: "Ocak", value: "01" },
    { label: "Şubat", value: "02" },
    { label: "Mart", value: "03" },
    { label: "Nisan", value: "04" },
    { label: "Mayıs", value: "05" },
    { label: "Haziran", value: "06" },
    { label: "Temmuz", value: "07" },
    { label: "Ağustos", value: "08" },
    { label: "Eylül", value: "09" },
    { label: "Ekim", value: "10" },
    { label: "Kasım", value: "11" },
    { label: "Aralık", value: "12" },
  ];

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2020; year <= currentYear; year++) {
      years.push(year.toString());
    }
    return years;
  };

  useEffect(() => {
    getTaxTypes()
      .then((resp) => {
        setTypes(resp.data.data);
        setİsDeclarationTypes(resp.data.data);
      })
      .catch((err) =>
        Swal.fire({
          title:
            err.response.data.message || " Vergi türlerini alırken hata oluştu",
          icon: "error",
        })
      );
  }, []);

  useEffect(() => {
    if (checked1) {
      setFilters({ ...filters, rangeIsPeriod: true });
    } else {
      setFilters({ ...filters, rangeIsPeriod: false });
    }
  }, [checked1, checked2]);

  const isQuarterPeriod = () => {
    const specialTypes = ["KGECICI", "GGECICI", "POSET"];
    let control = false;
    filters?.isDeclarationTypes.forEach((selected) => {
      const match = specialTypes.find((specific) => specific === selected);
      if (match) {
        control = true;
      }
    });
    return control;
  };

  useLayoutEffect(() => {
    localStorage.set("filters", JSON.stringify(filters));

    // isDeclarationTypes'in uzunluğuna bağlı olarak declarationStatus'u ayarla
    if (filters.isDeclarationTypes?.length > 0) {
      setFilters((filters) => ({
        ...filters,
        declarationStatus: true,
      }));

      const specialTypes = ["KGECICI", "GGECICI", "POSET"];
      filters?.isDeclarationTypes.forEach((selected) => {
        const match = specialTypes.find((specific) => specific === selected);
        if (match) {
          setChecked2(true);
          setChecked1(false);
        }
      });

      filters.declarationOperator === "AND"
        ? setFilters((filters) => ({ ...filters, declarationOperator: "AND" }))
        : setFilters((filters) => ({ ...filters, declarationOperator: "OR" }));
    } else {
      setFilters((filters) => ({
        ...filters,
        declarationStatus: null,
      }));
      setFilters((filters) => ({ ...filters, declarationOperator: null }));
    }
  }, [filters.isDeclarationTypes]);

  useLayoutEffect(() => {
    // localStorage.set("filters", JSON.stringify(filters))

    // isDeclarationTypes'in uzunluğuna bağlı olarak declarationStatus'u ayarla
    if (filters.types?.length > 0) {
      filters.typeOperation === "AND"
        ? setFilters((filters) => ({ ...filters, typeOperation: "AND" }))
        : setFilters((filters) => ({ ...filters, typeOperation: "OR" }));
    } else {
      setFilters((filters) => ({
        ...filters,
        typeOperation: null,
      }));
    }
  }, [filters.types]);

  const handleTaxTypeChange = (event, newValue) => {
    setFilters((prevData) => {
      return {
        ...prevData,
        type: newValue?.map((tax) => tax.value) || [], // newValue boş gelirse type'ı boş dizi yap
      };
    });
  };

  const handleisDeclarationTypesChange = (event, newValue) => {
    setFilters((prevData) => {
      return {
        ...prevData,
        isDeclarationTypes: newValue?.map((tax) => tax.value) || [], // newValue boş gelirse type'ı boş dizi yap
      };
    });
  };

  return (
    <Grid
      container
      width="20vw"
      sx={{
        justifyContent: "center",
        gap: 3,
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: 300, mt: 8, color: "#003366" }}
      >
        FİLTRELE
      </Typography>
      {page === "admin" ||
        (page === "taxpayer" && (
          <Grid item xs={11}>
            <TextField
              select
              fullWidth
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: e.target.value,
                })
              }
              value={filters?.status}
              label={page === "admin" ? "Kullanıcı Durumu" : "Tahakkuk Durumu"}
            >
              <MenuItem
                sx={{
                  color: darken(0.1, "green"),
                  fontWeight: "700",
                }}
                value={"Onaylandı"}
              >
                Onaylandı
              </MenuItem>
              <MenuItem
                sx={{
                  color: darken(0.1, "orange"),
                  fontWeight: "700",
                  display:
                    page === "taxpayers" || page === "admin" ? "none" : "block",
                }}
                value={"Onay bekliyor"}
              >
                Onay bekliyor
              </MenuItem>
              <MenuItem
                sx={{
                  color: darken(0.1, "red"),
                  fontWeight: "700",
                  display:
                    page === "taxpayers" || page === "admin" ? "none" : "block",
                }}
                value={"Hatalı"}
              >
                Hatalı
              </MenuItem>
              <MenuItem
                sx={{
                  color: "red",
                  fontWeight: "700",
                  display:
                    page === "taxpayers" || page === "admin" ? "none" : "block",
                }}
                value={"İptal"}
              >
                İptal
              </MenuItem>
            </TextField>
          </Grid>
        ))}

      <Grid
        item
        xs={11}
        sx={{
          display: page === "taxpayers" || page === "admin" ? "none" : "block",
        }}
      >
        <TextField
          select
          fullWidth
          onChange={(e) =>
            setFilters({ ...filters, systemStatus: e.target.value })
          }
          value={filters?.systemStatus}
          label={"Tahakkuk Sisteme Yüklenme Durumu"}
        >
          <MenuItem sx={{ fontWeight: "700", color: "green" }} value={1}>
            Yüklendi
          </MenuItem>
          <MenuItem sx={{ fontWeight: "700", color: "red" }} value={2}>
            Yüklenmedi{" "}
          </MenuItem>
        </TextField>
      </Grid>
      <Grid
        item
        xs={11}
        sx={{
          display: page === "taxpayers" || page === "admin" ? "none" : "block",
        }}
      >
        <TextField
          select
          fullWidth
          onChange={(e) =>
            setFilters({ ...filters, mailStatus: e.target.value })
          }
          value={filters?.mailStatus}
          label={"Tahakkuk E-posta durumu"}
        >
          <MenuItem sx={{ fontWeight: "700", color: "red" }} value={0}>
            Gönderilmedi
          </MenuItem>
          <MenuItem sx={{ fontWeight: "700", color: "gray" }} value={1}>
            Gönderilme sırasına alındı{" "}
          </MenuItem>
          <MenuItem sx={{ fontWeight: "700", color: "blue" }} value={2}>
            Gönderildi{" "}
          </MenuItem>
          <MenuItem sx={{ fontWeight: "700", color: "orange" }} value={3}>
            Alıcıya ulaştı{" "}
          </MenuItem>
          <MenuItem sx={{ fontWeight: "700", color: "green" }} value={4}>
            Okundu{" "}
          </MenuItem>
        </TextField>
      </Grid>

      <Grid
        item
        xs={11}
        sx={{
          display: page === "taxpayers" || page === "admin" ? "none" : "block",
        }}
      >
        <Autocomplete
          multiple
          id="tax-type-autocomplete"
          options={types}
          getOptionLabel={(option) => option?.value}
          limitTags={3}
          value={filters?.type?.map((item) =>
            types?.find((tax) => tax.value === item)
          )}
          onChange={handleTaxTypeChange}
          renderInput={(params) => (
            <TextField {...params} label="Vergi Türleri" type="text" />
          )}
        />
      </Grid>

      <Grid
        sx={{
          display: page === "taxpayers" ? "block" : "none",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Alert
          severity="info"
          sx={{
            backgroundColor: "#e0f7fa",
            color: "#00796b",
            padding: "5px",
          }}
        >
          <Typography variant="body1">
            Beyannamesi verilme durumuna göre listelemek için alt kısmı
            doldurunuz.
          </Typography>
        </Alert>
      </Grid>
      <Grid
        item
        xs={11}
        sx={{
          display: page === "taxpayers" ? "block" : "none",
        }}
      >
        <Autocomplete
          multiple
          id="tax-type-autocomplete"
          options={isDeclarationTypes}
          getOptionLabel={(option) => option?.value}
          limitTags={3}
          value={filters?.isDeclarationTypes?.map((item) =>
            isDeclarationTypes?.find((tax) => tax.value === item)
          )}
          onChange={handleisDeclarationTypesChange}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Vergi Türleri"
              type="text"
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={11}
        sx={{
          display: page === "taxpayers" ? "block" : "none",
        }}
      >
        <TextField
          size="small"
          select
          fullWidth
          onChange={(e) =>
            setFilters({ ...filters, declarationStatus: e.target.value })
          }
          value={filters?.declarationStatus ?? ""}
          label={"Vergi Beyanname Durumu"}
          disabled={filters?.isDeclarationTypes?.length === 0 ? true : false}
        >
          <MenuItem sx={{ fontWeight: "700", color: "gray" }} value={true}>
            Vergileri beyan edilmiş
          </MenuItem>
          <MenuItem sx={{ fontWeight: "700", color: "red" }} value={false}>
            Vergileri beyan edilmemiş
          </MenuItem>
        </TextField>
      </Grid>

      <Grid
        item
        xs={11}
        sx={{
          marginTop: "0px",
          display: page === "taxpayers" ? "block" : "none",
        }}
      >
        <TextField
          size="small"
          select
          fullWidth
          onChange={(e) =>
            setFilters({ ...filters, declarationOperator: e.target.value })
          }
          value={filters?.declarationOperator ?? ""}
          label={"Vergi Filtreleme Durumu"}
          disabled={filters?.isDeclarationTypes?.length === 0 ? true : false}
        >
          <MenuItem sx={{ fontWeight: "700", color: "green" }} value={"OR"}>
            En az biri varsa
          </MenuItem>
          <MenuItem sx={{ fontWeight: "700", color: "pink" }} value={"AND"}>
            Tamamı varsa
          </MenuItem>
        </TextField>

        <Grid container>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked1}
                size="small"
                onChange={(e) => {
                  let control = isQuarterPeriod();
                  if (control) {
                    return;
                  } else {
                    setChecked1(e.target.checked);
                    setChecked2(!e.target.checked);
                  }
                }}
              />
            }
            label="Dönem ile Filtrele"
            labelPlacement="end" // Checkbox'ı etiketin soluna yerleştirir
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked2}
                size="small"
                onChange={(e) => {
                  let control = isQuarterPeriod();
                  if (control) {
                    return;
                  } else {
                    setChecked1(!e.target.checked);
                    setChecked2(e.target.checked);
                  }
                }}
              />
            }
            label="Veriliş Tarihi ile Filtrele"
            labelPlacement="end" // Checkbox'ı etiketin soluna yerleştirir
          />
        </Grid>
        {checked1 && (
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item xs={12}>
              <Divider
                variant="middle"
                sx={{
                  "&::before, &::after": {
                    borderColor: "#e08434",
                    border: "1px solid #e08434",
                  },
                }}
              >
                <Typography
                  variant="overline"
                  display="block"
                  sx={{ textTransform: "none", margin: 0 }}
                >
                  Dönem Seçiniz
                </Typography>
              </Divider>
            </Grid>

            <Grid item xs={5.7}>
              <Autocomplete
                options={months}
                // Value olarak ay numarasını kullanarak label'ı gösteriyoruz
                value={
                  months.find((month) => month.value === filters.periodMonth) ||
                  null
                }
                onChange={(event, newValue) => {
                  setFilters((prevData) => ({
                    ...prevData,
                    periodMonth: newValue?.value || null, // newValue boş gelirse null yap
                  }));
                }}
                getOptionLabel={(option) => option.label} // Seçeneklerin label'ı gösteriliyor
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Ay Seçin" />
                )}
              />
            </Grid>
            <Grid item xs={5.7}>
              <Autocomplete
                options={getYears()} // Yılları dinamik olarak getiriyoruz
                value={filters?.periodYear}
                onChange={(event, newValue) => {
                  setFilters((prevData) => {
                    return {
                      ...prevData,
                      periodYear: newValue || null, // newValue boş gelirse type'ı boş dizi yap
                    };
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Yıl Seçin" />
                )}
              />
            </Grid>
          </Grid>
        )}

        {checked2 && (
          <Grid container>
            <Grid item xs={12}>
              <Divider
                variant="middle"
                sx={{
                  "&::before, &::after": {
                    borderColor: "#e08434",
                    border: "1px solid #e08434",
                  },
                }}
              >
                <Typography
                  variant="overline"
                  display="block"
                  sx={{ textTransform: "none", margin: 0 }}
                >
                  Veriliş Tarihi Seçiniz
                </Typography>
              </Divider>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment} locale="tr">
                <DatePicker
                  label="Başlangıç Tarihi"
                  value={filter?.startDate}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "42px", // Yüksekliği azalttık
                      fontSize: "0.9rem", // Yazı boyutunu biraz daha küçülttük
                    },
                    "& .MuiInputBase-input": {
                      padding: "2px 4px", // Üst ve alt padding'i azalttık
                      paddingRight: "24px",
                      paddingLeft: "10px", // Sağ taraftaki icon için alan bıraktık
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "1rem", // Label boyutunu küçülttük
                      transform: "translate(14px, 8px) scale(1)", // Label pozisyonunu ayarladık
                    },
                    "& .MuiInputLabel-shrink": {
                      transform: "translate(14px, -6px) scale(0.75)", // Küçülmüş label pozisyonunu ayarladık
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "1.1rem", // Icon boyutunu küçülttük
                      right: "2px", // Icon'u sağa yaklaştırdık
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(newValue) => {
                    setFilters((prevData) => {
                      return {
                        ...prevData,
                        startDate:
                          moment(newValue).format("DD/MM/YYYY") || null, // newValue boş gelirse type'ı boş dizi yap
                      };
                    });
                  }}
                />
                <DatePicker
                  label="Bitiş Tarihi"
                  value={filter?.endDate}
                  onChange={(newValue) => {
                    setFilters((prevData) => {
                      return {
                        ...prevData,
                        endDate: moment(newValue).format("DD/MM/YYYY") || null, // newValue boş gelirse type'ı boş dizi yap
                      };
                    });
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "42px", // Yüksekliği azalttık
                      fontSize: "0.9rem", // Yazı boyutunu biraz daha küçülttük
                    },
                    "& .MuiInputBase-input": {
                      padding: "2px 4px", // Üst ve alt padding'i azalttık
                      paddingRight: "24px",
                      paddingLeft: "10px", // Sağ taraftaki icon için alan bıraktık
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "1rem", // Label boyutunu küçülttük
                      transform: "translate(14px, 8px) scale(1)", // Label pozisyonunu ayarladık
                    },
                    "& .MuiInputLabel-shrink": {
                      transform: "translate(14px, -6px) scale(0.75)", // Küçülmüş label pozisyonunu ayarladık
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "1.1rem", // Icon boyutunu küçülttük
                      right: "2px", // Icon'u sağa yaklaştırdık
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            display: page === "taxpayers" ? "flex" : "none",
            marginTop: "21px",
            alignItems: "center",
            gap: 1,
            backgroundColor: "#00cfe8",
            "&:hover": {
              backgroundColor: darken(0.1, "#00cfe8"),
              borderColor: "#d9534f",
            },
          }}
          variant="contained"
          onClick={() => {
            if (checked1 && (!filters?.periodMonth || !filters?.periodYear)) {
              setIsEmpty(true);
              return;
            }
            if (checked2 && (!filters?.startDate || !filters?.endDate)) {
              setIsEmpty(true);
              return;
            }
            handleFetchData();
          }}
          disabled={filters?.isDeclarationTypes?.length < 1}
        >
          <SearchIcon style={{ color: "white" }} />{" "}
          {/* İkon ve metin arasında mesafe ayarlama */}
          Ara
        </Button>
        <Button
          sx={{
            height: "40px",
            marginTop: "20px",
            textTransform: "none",
            display: page === "taxpayers" ? "none" : "flex",
            alignItems: "center",
            gap: 1,
            backgroundColor: "#00cfe8",
            "&:hover": {
              backgroundColor: darken(0.1, "#00cfe8"),
              borderColor: "#d9534f",
            },
          }}
          variant="contained"
          onClick={() => {
            handleFetchData();
          }}
        >
          <SearchIcon color="white" />
          Ara
        </Button>
        <Button
          sx={{
            textTransform: "none",
            alignItems: "center",
            gap: 1,

            borderColor: "black",
            color: "black",
            marginTop: "20px",
            "&:hover": { backgroundColor: darken(0.4, "white") },
          }}
          variant="outlined"
          onClick={() => {
            setIsEmpty(false);
            page === "taxpayers"
              ? setFilters({
                  ...filters,
                  status: null,
                  types: [],
                  declarationStatus: [],
                  typeOperation: null,
                  isDeclarationTypes: [],
                  declarationOperator: null,
                  periodMonth: null,
                  periodYear: null,
                  startDate: null,
                  endDate: null,
                })
              : setFilters({
                  ...filters,
                  status: null,
                  types: [],
                  systemStatus: null,
                  mailStatus: null,
                  taxDeclared: [],
                  declarationStatus: [],
                  reportType: [],
                });
          }}
        >
          <FilterAltOffIcon color="white" />
          Filtreleri Kaldır
        </Button>
      </Grid>
      {isEmpty &&
        filters?.isDeclarationTypes?.length > 0 &&
        page === "taxpayers" && (
          <Grid item xs={11}>
            <Alert
              severity="error"
              sx={{
                borderRadius: "8px",
                border: "1px solid #041737",
              }}
            >
              Lütfen tüm alanları doldurunuz
            </Alert>
          </Grid>
        )}
      {page === "taxpayers" && (
        <Grid item xs={11}>
          <Alert
            severity="info"
            sx={{
              borderRadius: "8px",
              border: "1px solid #041737",
            }}
          >
            3 Aylık vergiler veriliş tarihine göre filtrelenmelidir.
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default FilterDrawer;
