import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Grid,
  Button,
  Menu,
  MenuItem,
  Typography,
  Box,
  Divider,
  Tooltip,
} from "@mui/material";
import UserProfileDropdown from "./profilMenu";
import { useState, useEffect } from "react";
import { getUser } from "../../api/user/getUser";
import Swal from "sweetalert2";
import DollarIcon from "@mui/icons-material/AttachMoney";
import EuroIcon from "@mui/icons-material/Euro";
import axios from "axios";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);

  const [usdRates, setUsdRates] = useState({ buying: null, selling: null });
  const [eurRates, setEurRates] = useState({ buying: null, selling: null });

  const [prevUsdRates, setPrevUsdRates] = useState({
    buying: null,
    selling: null,
  });
  const [prevEurRates, setPrevEurRates] = useState({
    buying: null,
    selling: null,
  });

  const [data, setData] = useState({
    mailTitle: "",
    title: "",
    email: "",
    number: [],
    ivd_password: "",
    vkn: "",
    created_at: "",
    status: "",
    reportRange: "20-29",
  });

  useEffect(() => {
    getUser()
      .then((resp) => setData(resp.data))
      .catch((err) =>
        Swal.fire({
          text:
            err.response.data.message ||
            "Müşavir bilgileri alınırken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
        })
      );
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (url) => {
    window.open(url, "_blank");
    handleClose();
  };

  useEffect(() => {
    const fetchRates = async () => {
      setIsLoading(true);

      try {
        const response = await axios.get(
          "https://hasanadiguzel.com.tr/api/kurgetir"
        );
        const data = response.data;

        const usd = data.TCMB_AnlikKurBilgileri.find(
          (rate) => rate.Isim === "ABD DOLARI"
        );
        const eur = data.TCMB_AnlikKurBilgileri.find(
          (rate) => rate.Isim === "EURO"
        );

        setPrevUsdRates(usdRates);
        setPrevEurRates(eurRates);

        setUsdRates({
          buying: usd ? usd.ForexBuying : null,
          selling: usd ? usd.ForexSelling : null,
        });

        setEurRates({
          buying: eur ? eur.ForexBuying : null,
          selling: eur ? eur.ForexSelling : null,
        });
      } catch (error) {
        console.error("Döviz kurları alınırken bir hata oluştu:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRates();
    const interval = setInterval(fetchRates, 300000); // 30000 saniyede bir güncelleme

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid
      container
      className="navbar"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5rem 1rem",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        border: "1px solid #e0e0e0",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            backgroundColor: "#2196f3",
            color: "#ffffff",
            textTransform: "none",
            fontSize: "0.875rem",
            fontWeight: "500",
            "&:hover": {
              backgroundColor: "#1976d2",
            },
          }}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
        >
          Rahat Uygulamalar
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 200,
              width: "20ch",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <MenuItem
            onClick={() => handleMenuItemClick("https://rahataktarim.com")}
          >
            Rahat Aktarım
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleMenuItemClick(
                "https://kurulus.rahatsistem.com.tr/account/login"
              )
            }
          >
            Rahat Sistem
          </MenuItem>
        </Menu>

        <Tooltip
          title="Veriler Türkiye Cumhuriyet Merkez Bankası'ndan her saat başı güncellenmektedir."
          arrow
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              padding: "0.5rem 1rem",
              backgroundColor: "#e3f2fd",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            {/* USD */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.1 }}>
              <DollarIcon sx={{ color: "#4caf50" }} />
              <Typography sx={{ fontWeight: "bold", color: "#424242" }}>
                USD/TRY:
              </Typography>

              <Box sx={{ display: "flex", gap: 0.5 }}>
                <Typography sx={{ color: "#424242", fontWeight: "bold" }}>
                  Alış: {usdRates.buying ? usdRates.buying.toFixed(2) : "Yok"}{" "}
                  {prevUsdRates.buying &&
                  usdRates.buying > prevUsdRates.buying ? (
                    <ArrowDropUpIcon sx={{ color: "green", ml: 1 }} />
                  ) : prevUsdRates.buying &&
                    usdRates.buying < prevUsdRates.buying ? (
                    <ArrowDropDownIcon sx={{ color: "red", ml: 1 }} />
                  ) : null}
                  {"-"}
                </Typography>

                <Typography sx={{ color: "#424242", fontWeight: "bold" }}>
                  Satış:{" "}
                  {usdRates.selling ? usdRates.selling.toFixed(2) : "Yok"}{" "}
                  {prevUsdRates.selling &&
                  usdRates.selling > prevUsdRates.selling ? (
                    <ArrowDropUpIcon sx={{ color: "green", ml: 1 }} />
                  ) : prevUsdRates.selling &&
                    usdRates.selling < prevUsdRates.selling ? (
                    <ArrowDropDownIcon sx={{ color: "red", ml: 1 }} />
                  ) : null}
                </Typography>
              </Box>
            </Box>

            <Divider orientation="vertical" flexItem />

            {/* EUR */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <EuroIcon sx={{ color: "#1976d2" }} />
              <Typography sx={{ fontWeight: "bold", color: "#424242" }}>
                EUR/TRY:
              </Typography>

              <Box sx={{ display: "flex", gap: 0.5 }}>
                <Typography sx={{ color: "#424242", fontWeight: "bold" }}>
                  Alış: {eurRates.buying ? eurRates.buying.toFixed(2) : "Yok"}{" "}
                  {prevEurRates.buying &&
                  eurRates.buying > prevEurRates.buying ? (
                    <ArrowDropUpIcon sx={{ color: "green", ml: 1 }} />
                  ) : prevEurRates.buying &&
                    eurRates.buying < prevEurRates.buying ? (
                    <ArrowDropDownIcon sx={{ color: "red", ml: 1 }} />
                  ) : null}
                  {"-"}
                </Typography>
                <Typography sx={{ color: "#424242", fontWeight: "bold" }}>
                  Satış:{" "}
                  {eurRates.selling ? eurRates.selling.toFixed(2) : "Yok"}{" "}
                  {prevEurRates.selling &&
                  eurRates.selling > prevEurRates.selling ? (
                    <ArrowDropUpIcon sx={{ color: "green", ml: 1 }} />
                  ) : prevEurRates.selling &&
                    eurRates.selling < prevEurRates.selling ? (
                    <ArrowDropDownIcon sx={{ color: "red", ml: 1 }} />
                  ) : null}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Tooltip>
      </Grid>
      <UserProfileDropdown title={data.title} email={data.email} />
    </Grid>
  );
};

export default Navbar;
