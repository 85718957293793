import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import WorkIcon from "@mui/icons-material/Work";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import NumbersIcon from "@mui/icons-material/Numbers";

const UserProfileInfoPage = ({ userInfo }) => {
  return (
    <Grid item md={12} xs={12} className="grid-area">
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={5} xs={10}>
              <Typography variant="h6" sx={{ ml: "2vh" }}>
                Kişisel Bilgiler
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Ünvan" secondary={userInfo.title} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <BadgeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="TC Kimlik Numarası"
                    secondary={userInfo.ivd_credentials?.tckn || ""}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LocalConvenienceStoreIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Vergi Kimlik Numarası"
                    secondary={userInfo.vkn || ""}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <NumbersIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dijital Vergi Dairesi Kodu"
                    secondary={userInfo.ivd_credentials?.ivd_code || ""}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <NumbersIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dijital Vergi Dairesi Şifresi"
                    secondary={userInfo.ivd_password || ""}
                  />
                </ListItem>
              </List>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "none", md: "block" } }}
            />

            <Grid item md={5}>
              <Typography variant="h6" sx={{ ml: "2vh" }}>
                İletişim Bilgileri
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Telefon No"
                    secondary={userInfo.number}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Email" secondary={userInfo.email} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserProfileInfoPage;
