import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../api/auth/forgotPassword";
import Swal from "sweetalert2";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleReset = (e) => {
    e.preventDefault();
    forgotPassword(email)
      .then((resp) =>
        Swal.fire({
          title: resp.data.message,
          icon: "success",
          confirmButtonText: "Tamam",
        }).then(() => {
          navigate("/login");
        })
      )
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
        });
      });
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
        padding: isMobile ? "2vh" : "5vh",
      }}
      spacing={2}
    >
      {!isMobile && (
        <Grid
          item
          md={8.5}
          sm={8.5}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            component="img"
            sx={{
              width: "60%",
              height: "60%",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
            alt="My Image"
            src={`images/forgot.png`}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        md={3.5}
        sm={3.5}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
          marginTop: isMobile ? "5vh" : "20vh",
          marginBottom: isMobile ? "5vh" : "20vh",
          backgroundColor: "#ffffff",
          padding: "2vh",
          width: isMobile ? "100%" : "auto",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography
            variant={isMobile ? "h4" : "h2"}
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <img
              src="images/rahatbeyan.png"
              style={{
                objectFit: "cover",
                width: isMobile ? "100%" : "70%",
                marginBottom: { xs: "0.5em", md: "0" },
                marginRight: { xs: "0", md: "1em" },
              }}
              alt="Logo"
            />
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "red",
              marginLeft: "auto",
              fontWeight: "700",
              marginRight: isMobile ? "1em" : "4em",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <img
              src="https://servicesuser.rahatsistem.com.tr/images/products/rahatsistem/rs-logo/by-rs/1x/by-rahatsistem-logo.png"
              style={{
                objectFit: "cover",
                width: isMobile ? "30%" : "20%",
                marginBottom: { xs: "0.5em", md: "0" },
                marginRight: { xs: "0", md: "2%" },
              }}
              alt="Logo"
            />
          </Typography>
          <Typography variant={isMobile ? "h6" : "h5"}>
            Parolanızı mı unuttunuz 🔒
          </Typography>
          <Typography>
            Lütfen sisteme kayıtlı E-postanızı girin, şifrenizi sıfırlamanız
            için size bağlantı göndereceğiz.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <TextField
            className="input-field"
            fullWidth
            placeholder="E-mail adresinizi giriniz"
            label="Email"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            className="custom-button"
            onClick={handleReset}
          >
            Şifremi Sıfırla
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "2vh",
            justifyContent: "center",
          }}
        >
          <Button
            variant="body2"
            onClick={() => navigate("/login")}
            className="unframed-button"
            sx={{
              color: "#786af2",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <ArrowBackIosNewIcon fontSize="small" />
            Giriş sayfasına dön
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
