/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const getNotificationList = async (
  id,
  pageNumber,
  pageSize,
  sort,
  startDate,
  endDate
) => {
  const token = await CheckToken();
  if (!token) {
    // Redirect to login page
    window.location.href = "/login";
  }

  return await axios.get(
    `${process.env.REACT_APP_API_URL}/notification/get-list`,
    {
      params: {
        id,
        pageNumber,
        pageSize,
        sort,
        startDate,
        endDate,
      },

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
