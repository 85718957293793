/** @format */
import axios from "axios";
import CheckToken from "../../utils/checkToken/CheckToken";
export const getSynchronisationList = async (pageSize,pageNumber,startDate ,endDate ,filter ) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    return await axios.get(
        `${process.env.REACT_APP_API_URL}/sync/get-sync`,
        {
            params: {
                pageSize,
                pageNumber,
                startDate ,
                endDate ,
                filter
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
