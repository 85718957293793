/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const handleAutoSendAccrualsAndDeclarations = async (ids, type, status) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    return await axios.post(
        `${process.env.REACT_APP_API_URL}/tax-payer/handle-auto-send-accruals-and-declarations`,
        {
            ids,
            type,
            status,
        },

        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
