import axios from 'axios';
import CheckToken from '../../../utils/checkToken/CheckToken';

export const getDailyReportAsExcel = async (selectedOption) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = '/login';
    }

    // taxType dizisini birleştirip virgülle ayırarak tek bir string yap
    const taxTypeString = selectedOption.taxType.join(',');

    // period içindeki boşlukları kaldır ve uygun formatı koru
    const periodWithoutSpaces = selectedOption.period.replace(/\s+/g, '');

    // URL'yi manuel olarak oluştur
    const url =selectedOption.tax==="D"? `${process.env.REACT_APP_API_URL}/tax/get-daily-report-as-excel?period=${periodWithoutSpaces}-${periodWithoutSpaces}&type=${"DIGER"}`: selectedOption.tax!=="M"? `${process.env.REACT_APP_API_URL}/tax/get-daily-report-as-excel?period=${periodWithoutSpaces}&type=${taxTypeString}`:
    selectedOption.taxType[0]==="3 AY" ? `${process.env.REACT_APP_API_URL}/tax/get-daily-report-as-excel?period=${periodWithoutSpaces}&type=${"MUHSGK"}`:
    `${process.env.REACT_APP_API_URL}/tax/get-daily-report-as-excel?period=${periodWithoutSpaces}-${periodWithoutSpaces}&type=${"MUHSGK"}`

    console.log('Request URL:', url); // Debug için eklendi

    return await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};
