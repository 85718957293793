/** @format */
import React from "react";
import { Chip, Tooltip, Badge, Typography, Button, Grid } from "@mui/material";
import { lighten, darken } from "polished";
import Icon from "@mdi/react";
import { mdiFilePdfBox } from "@mdi/js";
import { mdiEmailRemove } from "@mdi/js";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import DraftsIcon from "@mui/icons-material/Drafts";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Swal from "sweetalert2";
import sgk from "../../assets/images/SGK.png";
import date from "date-and-time";
import b from "../../assets/images/b.png";
import { filter } from "jszip";
import { MdCancelScheduleSend } from "react-icons/md";

const hasSameMonthAsPreviousRow = (params) => {
  const previousRowNode = params.api.getDisplayedRowAtIndex(
    params.rowIndex - 1
  );
  if (!previousRowNode) return true;
  const currentMonth = params.data.notificationDate.split("-")[1];
  const previousMonth = previousRowNode.data.notificationDate.split("-")[1];
  return currentMonth === previousMonth;
};

const createColumnDefs = (
  handlePdfClick,
  setOpenMailHistoryModal,
  setSelectedHistory
) => {
  return [
    {
      headerName: "",
      field: "",
      maxWidth: 50,
      minWidth: 50,
      checkboxSelection: true,

      filter: false,
      suppressMenu: true,
    },
    {
      width: "10%",
      maxWidth: 120,
      field: "notificationNo",
      headerName: "Belge No",
      suppressMenu: true,
      cellStyle: (props) => {
        return {
          color: "black",
          textAlign: "start",
          alignItems: "center",
          overflow: "hidden",
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },
    {
      width: "10%",
      maxWidth: 120,
      field: "tcknVkn",
      headerName: "VKN",
      suppressMenu: true,
      cellStyle: (props) => {
        let tcknVkn = props.data.tcknVkn; // props.data üzerinden tcknVkn değerine erişiyoruz

        // tcknVkn'nin uzunluğunu kontrol ediyoruz
        // Uzunluk 10 ise bu şartı sağlar, VKN olarak göster
        return {
          color: "black",
          fontWeight: "700",
          textAlign: "start",
          alignItems: "center",
          display: tcknVkn && tcknVkn.length === 10 ? "flex" : "none",
          overflow: "hidden",
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },

    {
      field: "sentDate",
      suppressMenu: true,
      width: "10%",
      minWidth: 100,
      headerName: "Gönderilme Tarihi",
      cellRenderer: (props) => (
        <Tooltip title="Sisteme yüklenme zamanı">
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {date.format(new Date(props.data.sentDate), "DD-MM-YYYY HH:mm")}
          </Typography>
        </Tooltip>
      ),
      cellStyle: (props) => {
        return {
          textAlign: "start",
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          // Kontrol fonksiyonunu kullanarak sınıf ekleyin
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },
    {
      width: "10%",
      minWidth: 100,
      field: "notificationDate",
      suppressMenu: true,
      headerName: "Tebliğ Tarihi",
      cellRenderer: (props) => (
        <Tooltip title="Tebliğ zamanı">
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {date.format(
              new Date(props.data.notificationDate),
              "DD-MM-YYYY HH:mm"
            )}
          </Typography>
        </Tooltip>
      ),
      cellStyle: (props) => {
        return {
          textAlign: "start",
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          // Kontrol fonksiyonunu kullanarak sınıf ekleyin
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },
    {
      width: "10%",
      minWidth: 100,
      field: "notificationType",
      flex: 0.7,
      suppressMenu: true,
      headerName: "Tür",
      cellStyle: (props) => {
        return {
          textAlign: "start",
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          // Kontrol fonksiyonunu kullanarak sınıf ekleyin
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },
    {
      width: "10%",
      minWidth: 100,
      field: "sendingUnit",
      filter: false,
      suppressMenu: true,
      headerName: "Gönderen Birim",
      cellStyle: (props) => {
        return {
          textAlign: "start",
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          // Kontrol fonksiyonunu kullanarak sınıf ekleyin
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },
    {
      width: "10%",
      maxWidth: 110,
      field: "tahakkuklar",
      suppressMenu: true,
      headerName: "Tebligat Eki",
      checkboxSelection: false,
      filter: false,

      cellRenderer: (props) => (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            overflow: "hidden",
            gap: 5,
            alignItems: "center",
            position: "relative", // Eklendi
          }}
        >
          <Tooltip
            title={
              props?.data?.rDriveNotificationID
                ? "Tebligatı görmek için tıklayın"
                : "Sayfayı yenileyerek sisteme yüklenen Tebligatları görebilirsiniz."
            }
          >
            <span>
              <Icon
                path={mdiFilePdfBox}
                size={1.2}
                color="red"
                style={{
                  display: props?.data?.rDriveNotificationID ? "block" : "none",
                  cursor: props?.data?.rDriveNotificationID ? "pointer" : "",
                  width: "40px",
                }}
                onClick={() =>
                  handlePdfClick(props?.data?.rDriveNotificationID)
                }
              />

              <Typography
                sx={{
                  fontSize: "11px",
                  display: props?.data?.rDriveNotificationID ? "none" : "block",
                }}
              >
                {props?.data?.rDriveNotificationID
                  ? "Tebligat sisteme yükleniyor..."
                  : "Tebligat pdf'i yok"}
              </Typography>
            </span>
          </Tooltip>{" "}
        </div>
      ),
      cellStyle: (props) => {
        return {
          textAlign: "start",
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          // Kontrol fonksiyonunu kullanarak sınıf ekleyin
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },

    {
      width: "10%",
      maxWidth: 115,
      field: "status",
      suppressMenu: true,
      headerName: "Durum",
      cellRenderer: (props) => (
        <Chip
          size="small"
          sx={{
            width: "100%",
            borderRadius: "4px",
            backgroundColor:
              props?.data?.status === "Onaylandı"
                ? lighten(0.4, "#5cb85c")
                : props?.data?.status === "İptal"
                ? lighten(0.3, "#d9534f")
                : props?.data?.status === "Hatalı"
                ? lighten(0.1, "#d9534f")
                : lighten(0.3, "#f0ad4e"),
            color:
              props?.data?.status === "Onaylandı"
                ? "#5cb85c"
                : props?.data?.status === "İptal"
                ? "#d9534f"
                : props?.data?.status === "Hatalı"
                ? lighten(0.4, "#d9534f")
                : "#f0ad4e",
          }}
          label={props?.data?.status}
        />
      ),
      cellStyle: (props) => {
        return {
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },

    {
      flex: 1.5,
      field: "mailStatus",
      filter: false,
      suppressMenu: true,
      headerName: "E-posta Durumu",
      cellRenderer: (props) => (
        <Grid
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            // display: props.data.status === "Onaylandı" ? "flex" : "none",
          }}
        >
          <Tooltip title="Gönderilmedi">
            <Badge
              badgeContent={props.data.mailStatus[0]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "red",
                  fontWeight: "700",
                  fontSize: "11px",
                },
              }}
            >
              <Icon path={mdiEmailRemove} color="red" size={1} />
            </Badge>
          </Tooltip>{" "}
          <Tooltip title="Gönderilme sırasına alındı">
            {" "}
            <Badge
              badgeContent={props.data.mailStatus[1]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  fontWeight: "700",
                  backgroundColor: "gray",
                  fontSize: "11px",
                },
              }}
            >
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnklEQVR4nN2VO0sDQRSFvwiiCCZgYVpBwYCND2Il2NiJnRZ2qeyUFIqFlVqlsvIPiIKdgqU2vlCJgoKFIgGtlCiCBuxEGTiBONlJ9pHKA5dhZ+aew717zy78ZwwCGWAmRGSAgVrkrcAu8NOA2AFavERWdWEZSANDISINrIjHrFW4AY4a1PJj8VWhqMN4RIG4eIouEVPmG7CodxQEzXr5L+JxilwCeV0qANNArA55TPcKysuLxymyqaQp4EFJt3r2whhwpXuPqqRJPDVF7PKfRbIvDxn0AXtWeytH1rdIGe0a6xLwDZxrLWnfnNsILFJGElgHPrUm1VrjjTlgAZgEEsA4cB9GxEYKOPNw+weQBTqjiqSA9wriQ+DLEsu5RLZ8CMQ8KugHTj2qGrGT8xrHer5Ie5AZkROP/W07OauDDWBCHrCjC5gNIGK88wfGRGsaT9cnfEmesPfNF7hX6zBwrf1XVzs6gFFHJT1yvy1yJ7OaOKgYAuOpUEhoTP38vOaJgKwPAWPGNiIiV0egO6pAGcYHZkyf5LMLtShyBYHxC5+9ydO5kfYjAAAAAElFTkSuQmCC" />
            </Badge>
          </Tooltip>
          <Tooltip title="Gönderildi">
            {" "}
            <Badge
              badgeContent={props.data.mailStatus[2]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "blue",
                  fontWeight: "700",
                  fontSize: "11px",
                },
              }}
            >
              <ForwardToInboxIcon sx={{ color: "blue" }} />
            </Badge>
          </Tooltip>
          <Tooltip
            title="Alıcıya ulaştı"
            sx={{
              display: props?.data?.mailStatus === "Alındı" ? "block" : "none",
            }}
          >
            {" "}
            <Badge
              badgeContent={props.data.mailStatus[3]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "orange",
                  fontWeight: "700",
                  fontSize: "11px",
                },
              }}
            >
              <MarkEmailReadIcon sx={{ color: "orange" }} />
            </Badge>
          </Tooltip>
          <Tooltip
            title="Okundu"
            sx={{
              display: props?.data?.mailStatus === "Okundu" ? "block" : "none",
            }}
          >
            {" "}
            <Badge
              badgeContent={props.data.mailStatus[4]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "green",
                  fontWeight: "700",
                  fontSize: "11px",
                },
              }}
            >
              <DraftsIcon sx={{ color: "green" }} />
            </Badge>
          </Tooltip>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#11235A",
            }}
            size="small"
            variant="contained"
            onClick={() => {
              setSelectedHistory([props.data.id, "email"]);
              setOpenMailHistoryModal(true);
            }}
          >
            Detay
          </Button>
        </Grid>
      ),
      cellStyle: (props) => {
        return {
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },
    {
      flex: 1.5,
      field: "whatsappStatus",
      suppressMenu: true,
      filter: false,
      headerName: "Whatsapp Durumu",
      cellRenderer: (props) => (
        <Grid
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            // display: props.data.status === "Onaylandı" ? "flex" : "none",
          }}
        >
          <Tooltip title="Gönderilmedi">
            <Badge
              badgeContent={props.data?.whatsappStatus?.[0]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "#ff4800",
                  fontWeight: "700",
                  fontSize: "11px",
                },
              }}
            >
              <MdCancelScheduleSend size={22} color="#ff4800" />
            </Badge>
          </Tooltip>{" "}
          <Tooltip title="Hata Oluştu">
            {" "}
            <Badge
              badgeContent={props.data?.whatsappStatus?.[4]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "red",
                  fontWeight: "700",
                  fontSize: "11px",
                },
              }}
            >
              <Icon path={mdiEmailRemove} color="red" size={1} />
            </Badge>
          </Tooltip>
          <Tooltip title="Gönderilme sırasına alındı">
            {" "}
            <Badge
              badgeContent={props.data.whatsappStatus[1]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  fontWeight: "700",
                  backgroundColor: "gray",
                  fontSize: "11px",
                },
              }}
            >
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnklEQVR4nN2VO0sDQRSFvwiiCCZgYVpBwYCND2Il2NiJnRZ2qeyUFIqFlVqlsvIPiIKdgqU2vlCJgoKFIgGtlCiCBuxEGTiBONlJ9pHKA5dhZ+aew717zy78ZwwCGWAmRGSAgVrkrcAu8NOA2AFavERWdWEZSANDISINrIjHrFW4AY4a1PJj8VWhqMN4RIG4eIouEVPmG7CodxQEzXr5L+JxilwCeV0qANNArA55TPcKysuLxymyqaQp4EFJt3r2whhwpXuPqqRJPDVF7PKfRbIvDxn0AXtWeytH1rdIGe0a6xLwDZxrLWnfnNsILFJGElgHPrUm1VrjjTlgAZgEEsA4cB9GxEYKOPNw+weQBTqjiqSA9wriQ+DLEsu5RLZ8CMQ8KugHTj2qGrGT8xrHer5Ie5AZkROP/W07OauDDWBCHrCjC5gNIGK88wfGRGsaT9cnfEmesPfNF7hX6zBwrf1XVzs6gFFHJT1yvy1yJ7OaOKgYAuOpUEhoTP38vOaJgKwPAWPGNiIiV0egO6pAGcYHZkyf5LMLtShyBYHxC5+9ydO5kfYjAAAAAElFTkSuQmCC" />
            </Badge>
          </Tooltip>
          <Tooltip title="Alıcıya ulaştı">
            {" "}
            <Badge
              badgeContent={props.data?.whatsappStatus?.[2]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "orange",
                  fontWeight: "700",
                  fontSize: "11px",
                },
              }}
            >
              <MarkEmailReadIcon sx={{ color: "orange" }} />
            </Badge>
          </Tooltip>
          <Tooltip
            title="Okundu"
            sx={{
              display:
                props?.data?.whatsappStatus === "Okundu" ? "block" : "none",
            }}
          >
            {" "}
            <Badge
              badgeContent={props.data?.whatsappStatus?.[3]}
              size="small"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "green",
                  fontWeight: "700",
                  fontSize: "11px",
                },
              }}
            >
              <DraftsIcon sx={{ color: "green" }} />
            </Badge>
          </Tooltip>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#11235A",
            }}
            size="small"
            variant="contained"
            onClick={() => {
              setSelectedHistory([props.data.id, "whatsapp"]);
              setOpenMailHistoryModal(true);
            }}
          >
            Detay
          </Button>
        </Grid>
      ),
      cellStyle: (props) => {
        return {
          borderTop: hasSameMonthAsPreviousRow(props)
            ? ""
            : "2px solid #1976d2",
        };
      },
    },
  ];
};

export default createColumnDefs;
