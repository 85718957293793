/** @format */
import localStorage from "local-storage";
import PersonIcon from "@mui/icons-material/Person";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { lighten } from "polished";
const createColumnDefs = (navigate, setSelecteIds) => {
  return [
    {
      field: "id",
      headerName: "",
      cellStyle: {
        textAlign: "start",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
      },
      suppressMenu: true,
      enableCellTextSelection: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      filter: false,
      cellRenderer: (params) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            navigate(`/mukellef/tebligat/${params.data.id}`);
            localStorage.set("scrollPosition", Math.floor(window.scrollY));
          }}
          onMouseDown={(event) => {
            if (event.button === 1) {
              // Orta tıklama (middle click) için button 1 kontrolü
              event.preventDefault(); // Varsayılan davranışı engelle (örneğin, tıklanan butonun odaklanması)
              window.open(`/mukellef/tebligat/${params.data.id}`, "_blank");
            }
          }}
          sx={{ textTransform: "none", whiteSpace: "nowrap" }}
          startIcon={<PersonIcon />}
        >
          Mükkelefi gör
        </Button>
      ),
    },
    {
      field: "vkn",
      headerName: "VKN",
      width: "10%",
      minWidth: 100,
      filter: "agTextColumnFilter", // Doğru kullanım

      cellStyle: {
        textAlign: "start",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
      },
      suppressMenu: true,
      cellRenderer: (params) => (
        <Tooltip
          title={
            params.data?.vkn?.length === 10
              ? ""
              : "Vergi Kimlik No formatı yanlış lütfen düzeltin"
          }
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "700",
              color: params.data?.vkn?.length === 10 ? "" : "red",
              backgroundColor:
                params.data?.vkn?.length === 10 ? "" : lighten(0.4, "red"),
            }}
          >
            {params.data.vkn}{" "}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "tckn",
      headerName: "TCKN",
      width: "10%",
      minWidth: 110,
      filter: "agTextColumnFilter", // Doğru kullanım
      cellStyle: {
        textAlign: "start",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
      },
      suppressMenu: true,
      cellRenderer: (params) => (
        <Tooltip
          title={
            params.data?.tckn?.length === 11
              ? ""
              : "Tc Kimlik No formatı yanlış lütfen düzeltin"
          }
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "700",
              color: params.data.tckn?.length === 11 ? "" : "red",
              backgroundColor:
                params.data.tckn?.length === 11 ? "" : lighten(0.4, "red"),
            }}
          >
            {params.data.tckn}{" "}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "title",
      filter: "agTextColumnFilter", // Doğru kullanım

      headerName: "Ünvan",
      minWidth: 150,
      flex: 1,
      cellStyle: {
        textAlign: "start",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
      },
      suppressMenu: true,
    },
    {
      field: "number",
      filter: "agTextColumnFilter", // Doğru kullanım
      headerName: "Telefon ",
      width: "10%",
      minWidth: 120,
      cellStyle: {
        textAlign: "start",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
      },
      suppressMenu: true,
      cellRenderer: (params) => (
        <Grid
          item
          sx={{
            flexDirection: "row",
            display: "flex",
            overflow: "hidden",
            gap: 0.3,
          }}
        >
          {params.data.number.map((item, index) => (
            <Tooltip
              key={index}
              title={
                item.startsWith("5")
                  ? ""
                  : "Telefon numarası formatı yanlış lütfen düzeltin"
              }
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: item.startsWith("5") ? "" : "red",
                  backgroundColor: item.startsWith("5")
                    ? ""
                    : lighten(0.4, "red"),
                }}
              >
                {item} {index === params.data.number.length - 1 ? "" : ","}
              </Typography>
            </Tooltip>
          ))}
        </Grid>
      ),
    },
    {
      field: "mail",
      headerName: "E-posta Adresi",
      width: "15%",
      filter: "agTextColumnFilter", // Doğru kullanım

      minWidth: 180,
      cellStyle: {
        textAlign: "start",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
      },
      suppressMenu: true,
    },
    {
      field: "adress",
      headerName: "Adres",
      filter: "agTextColumnFilter", // Doğru kullanım
      width: "20%", // Yüzdelik olarak genişlik belirlenebilir
      minWidth: 220, // Minimum genişlik belirlenebilir
      cellStyle: {
        textAlign: "start",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
        flexGrow: 1, // Hücrenin genişlemesini sağlar
      },
      suppressMenu: true,
    },
    {
      headerName: "Durum",
      field: "is_deleted",
      flex: 0,
      cellRenderer: (params) => {
        // Gelen veriye göre renk ve metin belirle
        let buttonText = "";
        if (params.data.is_deleted === false) {
          buttonText = "Aktif";
        } else {
          buttonText = "Pasif";
        }

        // Buton oluştur
        return (
          <button
            sx={{
              textTransform: "none",
              mb: "3%",
            }}
            style={{
              backgroundColor:
                buttonText === "Aktif"
                  ? lighten(0.4, "#5cb85c")
                  : lighten(0.3, "#f0ad4e"),
              color: buttonText === "Aktif" ? "#12450f" : "#b3545c",
              padding: "6px 12px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              width: "130px", // Tüm butonların genişliği (örneğin 200 piksel)
              "&:hover": {
                backgroundColor: "#1565c0", // Hover rengi
              },
            }}
          >
            {buttonText}
          </button>
        );
      },

      filterParams: {
        valueFormatter: function (params) {
          if (params.value === false) {
            return "Aktif";
          } else if (params.value === true) {
            return "Pasif";
          }
        },
      },
    },
  ];
};
export default createColumnDefs;
