/** @format */

import {
  Grid,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Button,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  FormGroup,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateTaxPayer } from "../../api/taxPayer/updateTaxPayer";
import Swal from "sweetalert2";
import "../../swall.css";
import { getTaxTypes } from "../../api/taxTypes/getList";
import SaveIcon from "@mui/icons-material/Save";
import { lighten, darken } from "polished";
import { PatternFormat } from "react-number-format";

const TaxPayerDetailsModal = ({ taxPayerData, setReload, reload }) => {
  const [data, setData] = useState(taxPayerData); // null ile başlatın
  const [mukVkn, setMukVkn] = useState(null);
  const [types, setTypes] = useState([]);
  const [accrualTypes, setAccrualTypes] = useState([]);
  useEffect(() => {
    setData(taxPayerData);
    setMukVkn(taxPayerData.vkn);
  }, [taxPayerData]);

  const handleTaxTypeChange = (event, newValue) => {
    setData((prevData) => ({
      ...prevData,
      taxes: newValue?.map((tax) => tax.value),
    }));
  };

  const handleTaxAccrualChange = (event, newValues) => {
    let selectedValues;
    if (newValues.some((item) => item.value === "HEPSİ")) {
      selectedValues = ["HEPSİ"];
    } else {
      selectedValues = newValues.map((item) => item.value);
    }
    setData((prevData) => ({
      ...prevData,
      accrualTaxes: selectedValues,
    }));
  };

  const handleTaxDeclarationChange = (event, newValues) => {
    let selectedValues;
    if (newValues.some((item) => item.value === "HEPSİ")) {
      selectedValues = ["HEPSİ"];
    } else {
      selectedValues = newValues.map((item) => item.value);
    }
    setData((prevData) => ({
      ...prevData,
      declarationTaxes: selectedValues,
    }));
  };
  const declarationTax = [
    { value: "HEPSİ", label: "HEPSİ" },
    { value: "KURUMLAR", label: "KURUMLAR" },
    { value: "GELIR", label: "GELIR" },
    { value: "GGECICI", label: "GGECICI" },
    { value: "KGECICI", label: "KGECICI" },
  ];

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name.startsWith("accrual") || name.startsWith("declaration")) {
      const type = name.startsWith("accrual")
        ? "autoSendAccruals"
        : "autoSendDeclarations";
      const key = name
        .replace("accrual", "")
        .replace("declaration", "")
        .toLowerCase();

      setData((prevData) => ({
        ...prevData,
        [type]: {
          ...prevData[type],
          [key]: name?.endsWith("Main") ? value : checked,
        },
      }));
    } else if (name.includes("ivd")) {
      setData((prevData) => {
        const updatedIvdCredentials = [...prevData.ivd_credentials];
        if (updatedIvdCredentials.length > 0) {
          updatedIvdCredentials[0] = {
            ...updatedIvdCredentials[0],
            ivd_password: value,
          };
        }
        return {
          ...prevData,
          ivd_credentials: updatedIvdCredentials,
        };
      });
    } else if (name.includes("sgk")) {
      setData((prevData) => ({
        ...prevData,
        sgk_credentials: [
          {
            ...prevData.sgk_credentials?.[0],
            [name]: value,
          },
        ],
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setData((prevData) => ({
      ...prevData,
      autoSendAccruals: {
        main: prevData.autoSendAccruals?.main,
        mail: prevData.autoSendAccruals?.mail,
        whatsapp: prevData.autoSendAccruals?.whatsapp,
      },
      autoSendDeclarations: {
        main: prevData.autoSendDeclarations?.main,
        mail: prevData.autoSendDeclarations?.mail,
        whatsapp: prevData.autoSendDeclarations?.whatsapp,
      },
    }));
  };

  useEffect(() => {
    getTaxTypes()
      .then((resp) => {
        const originalTypes = resp.data.data;
        setTypes(resp.data.data);

        const HEPSİOption = {
          id: 0,
          value: "HEPSİ",
          label: "HEPSİ",
        };

        const updatedTypes = [HEPSİOption, ...originalTypes];
        setAccrualTypes(updatedTypes);
      })
      .catch((err) =>
        Swal.fire({
          text: "Vergi türleri alınırken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        })
      );
  }, []);

  const PatternFormatCustom = React.forwardRef(function PatternFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        key={props.key}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="### ### ## ##"
      />
    );
  });

  const handleSave = () => {
    const {
      title,
      tckn,
      vkn,
      adress,
      number,
      mail,
      taxes,
      declarationTaxes,
      accrualTaxes,
      ivd_credentials,
      sgk_credentials,
      autoSendAccruals,
      autoSendDeclarations,
    } = data;
    const newDataToSend = {
      title: title?.replace(/i/g, "İ").toUpperCase(),
      tckn,
      vkn,
      adress: adress?.replace(/i/g, "İ").toUpperCase(),
      number: number?.map((num) => num.replace(/\s/g, "")),
      mail: mail?.filter((mail) => mail.trim() !== ""),
      taxes,
      declarationTaxes,
      accrualTaxes,
      ivd_credentials: ivd_credentials,
      sgk_credentials: sgk_credentials || {},
      autoSendAccruals: autoSendAccruals
        ? autoSendAccruals
        : { main: false, mail: false, whatsapp: false },
      autoSendDeclarations: autoSendDeclarations
        ? autoSendDeclarations
        : { main: false, mail: false, whatsapp: false },
    };

    Swal.fire({
      text: "Mükellef bilgileri güncellenecek.Devam etmek istiyor musunuz?",
      icon: "question",
      showDenyButton: true,
      denyButtonText: "Hayır, devam etme",
      confirmButtonText: "Evet, devam et",
      customClass: { htmlContainer: ".swal2-container" },
    }).then((result) => {
      //   setDetailModalOpen(false);
      if (result.isConfirmed) {
        updateTaxPayer(mukVkn, newDataToSend)
          .then((resp) => {
            Swal.fire({
              text: resp.data.message,
              icon: "success",
              confirmButtonText: "Tamam",
            });
            setReload(!reload);
          })
          .catch((err) =>
            Swal.fire({
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            })
          );
      }
    });
  };

  const handleAddField = (fieldName) => {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: [...prevData[fieldName], ""],
    }));
  };

  const handleRemoveField = (fieldName, index) => {
    setData((prevData) => {
      const updatedValues = [...prevData[fieldName]];
      updatedValues.splice(index, 1);
      return {
        ...prevData,
        [fieldName]: updatedValues,
      };
    });
  };

  const handleChangeField = (fieldName, index, value) => {
    setData((prevData) => {
      const updatedValues = [...prevData[fieldName]];
      updatedValues[index] = value;
      return {
        ...prevData,
        [fieldName]: updatedValues,
      };
    });
  };
  return (
    <Grid
      container
      spacing={3}
      sx={{
        width: "100%",
        margin: "0 auto",
        padding: "4rem",
        maxHeight: "75vh",
        height: "75vh",
      }}
    >
      {/* Title Section */}
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", marginBottom: "2rem" }}
        >
          Mükellef Bilgileri
        </Typography>
        {/* Sözleşme Tarihi ve Durumu */}
        <Grid
          item
          xs={4}
          sx={{
            flexDirection: "row",
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography sx={{ display: "flex", gap: 1 }}>
            Sözleşme Tarihi:{" "}
            <Typography
              sx={{
                fontWeight: "700",
              }}
            >
              {data.ivd_credentials?.[0]?.soztarih || "-"}
            </Typography>
          </Typography>
          <Typography sx={{ display: "flex", gap: 1 }}>
            Durum:{" "}
            <Typography
              sx={{
                fontWeight: "700",
                color: data.is_deleted ? "red" : "green",
              }}
            >
              {data.is_deleted ? "Pasif" : "Aktif"}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: "rgba(255, 182, 193, 0.3)", // Pastel pembe renk

              borderRadius: "4px 4px 0 0 ", // Köşeleri yuvarlatmak için
              "&:hover": {
                backgroundColor: "rgba(255, 182, 193, 0.5)", // Hover efekti için
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {data.title}
              </Typography>
              <Box sx={{ marginTop: "3px" }}>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  VKN: {data.vkn}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "2%" }}>
            <Grid container spacing={3}>
              {/* İlk Sütun */}
              <Grid item xs={12} sm={6} container spacing={3}>
                {/* Ünvan ve Dijital Vergi Bilgileri */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    value={data.title}
                    label="Ünvan"
                    name="title"
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    value={data.ivd_credentials?.[0]?.ivd_password}
                    label="Dijital Vergi Dairesi Şifresi"
                    name="ivd_password"
                    onChange={(e) => handleChange(e)}
                    size="small"
                  />
                </Grid>

                {/* TCKN ve VKN */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    value={data.tckn}
                    label="TCKN"
                    name="tckn"
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    value={data.vkn}
                    label="VKN"
                    name="vkn"
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
              </Grid>

              {/* İkinci Sütun */}
              <Grid item xs={12} sm={6} container spacing={3}>
                {/* Adres ve Vergi Türleri */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={data.adress}
                    label="Adres"
                    name="adress"
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tax-type-autocomplete"
                    options={types}
                    getOptionLabel={(option) => option?.value}
                    value={types?.filter((type) =>
                      data?.taxes?.includes(type.value)
                    )}
                    limitTags={3}
                    onChange={handleTaxTypeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vergi Türleri"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/* SGK Bilgileri Accordion */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: "rgba(173, 216 , 230 , 0.3)", // Pastel pembe renk
              "&:hover": {
                backgroundColor: "rgba(170, 182, 193, 0.5)", // Hover efekti için
                borderRadius: "4px 4px 0 0 ", // Köşeleri yuvarlatmak için
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                SGK Bilgileri{" "}
              </Typography>
              <Box sx={{ marginTop: "3px" }}>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  Kullanıcı Adı: {data.sgk_credentials?.[0]?.sgk_username}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "2%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  value={data.sgk_credentials?.[0]?.sgk_workplace_password}
                  label="SGK işyeri şifresi"
                  name="sgk_workplace_password"
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  value={data.sgk_credentials?.[0]?.sgk_username}
                  label="SGK kullanıcı adı"
                  name="sgk_username"
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  value={data.sgk_credentials?.[0]?.sgk_system_password}
                  label="SGK Sistem şifresi"
                  name="sgk_system_password"
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: "rgba(144, 238, 144, 0.3)", // Pastel yeşil renk
              "&:hover": {
                backgroundColor: "rgba(120, 200, 120, 0.5)", // Hover efekti için
                borderRadius: "4px 4px 0 0 ", // Köşeleri yuvarlatmak için
              },
            }}
          >
            <Typography variant="h6">
              Gönderilecek Tahakkuk ve Beyanname Türleri
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {/* Tahakkuk Vergi Türleri */}
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tahakkuk Vergi Türleri
                </Typography>
                <TextField
                  select
                  fullWidth
                  label="Mükellefin tahakkukları otomatik gönderilsin mi?"
                  value={data.autoSendAccruals?.main}
                  onChange={handleChange}
                  name="accrualMain"
                  size="small"
                >
                  <MenuItem value={true}>Evet</MenuItem>
                  <MenuItem value={false}>Hayır</MenuItem>
                </TextField>
                <Box sx={{ marginTop: "10px" }}></Box>
                <Autocomplete
                  multiple
                  id="accrualtax-type-autocomplete"
                  options={accrualTypes}
                  getOptionLabel={(option) => option?.value}
                  value={accrualTypes?.filter((type) =>
                    data.accrualTaxes?.includes("HEPSİ")
                      ? type.value === "HEPSİ"
                      : data.accrualTaxes?.includes(type.value)
                  )}
                  limitTags={3}
                  onChange={handleTaxAccrualChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Vergi Türleri" size="small" />
                  )}
                />
                <FormGroup row sx={{ marginTop: "10px" }}>
                  <FormControlLabel
                    disabled={!data.autoSendAccruals?.main}
                    control={
                      <Switch
                        checked={data.autoSendAccruals.mail}
                        onChange={handleChange}
                        name="accrualMail"
                      />
                    }
                    label="E-posta"
                  />
                  <FormControlLabel
                    disabled={!data.autoSendAccruals?.main}
                    control={
                      <Switch
                        checked={data.autoSendAccruals.whatsapp}
                        onChange={handleChange}
                        name="accrualWhatsapp"
                      />
                    }
                    label="Whatsapp"
                  />
                </FormGroup>
              </Grid>

              {/* Dikey Çizgi */}
              <Grid item xs={1}></Grid>
              {/* Beyanname Vergi Türleri */}
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Beyanname Vergi Türleri
                </Typography>
                <TextField
                  select
                  fullWidth
                  label="Mükellefin beyannameleri otomatik gönderilsin mi?"
                  value={data.autoSendDeclarations?.main}
                  onChange={handleChange}
                  name="declarationMain"
                  size="small"
                >
                  <MenuItem value={true}>Evet</MenuItem>
                  <MenuItem value={false}>Hayır</MenuItem>
                </TextField>
                <Box sx={{ marginTop: "10px" }}></Box>

                <Autocomplete
                  multiple
                  id="declarationTax-type-autocomplete"
                  options={declarationTax}
                  getOptionLabel={(option) => option?.value}
                  value={declarationTax.filter((type) =>
                    data.declarationTaxes?.includes("HEPSİ")
                      ? type.value === "HEPSİ"
                      : data.declarationTaxes?.includes(type.value)
                  )}
                  limitTags={3}
                  onChange={handleTaxDeclarationChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Vergi Türleri" size="small" />
                  )}
                />
                <FormGroup row sx={{ marginTop: "10px" }}>
                  <FormControlLabel
                    disabled={!data.autoSendDeclarations?.main}
                    control={
                      <Switch
                        checked={data.autoSendDeclarations?.mail}
                        onChange={handleChange}
                        name="declarationMail"
                      />
                    }
                    label="E-posta"
                  />
                  <FormControlLabel
                    disabled={!data.autoSendDeclarations?.main}
                    control={
                      <Switch
                        checked={data.autoSendDeclarations?.whatsapp}
                        onChange={handleChange}
                        name="declarationWhatsapp"
                      />
                    }
                    label="Whatsapp"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* E-posta ve Telefon Accordion */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">E-posta ve Telefon Numarası</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {/* Telefon Numaraları Bölümü */}
              <Grid item xs={6}>
                <Typography
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {data?.number?.length < 2
                    ? "Telefon numarası:"
                    : "Telefon numaraları:"}

                  {data?.number?.map((number, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <TextField
                        sx={{ width: "80%", marginTop: "2%" }}
                        value={number
                          .toString()
                          .replace(
                            /(\d{3})(\d{3})(\d{2})(\d{2})/,
                            "$1 $2 $3 $4"
                          )}
                        key={index}
                        placeholder="5## ### ## ##"
                        label={`Numara ${index + 1}`}
                        onBlur={(e) =>
                          handleChangeField("number", index, e.target.value)
                        }
                        InputProps={{
                          inputComponent: PatternFormatCustom,
                        }}
                        size="small"
                      />
                      {index > 0 && (
                        <IconButton
                          onClick={() => handleRemoveField("number", index)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  ))}
                  <Tooltip title="Numara eklemek için tıklayın">
                    <AddIcon
                      onClick={() => handleAddField("number")}
                      size="small"
                      sx={{ marginLeft: "4vw" }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>

              {/* E-posta Adresleri Bölümü */}
              <Grid item xs={6}>
                <Typography
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {data?.mail?.length < 2
                    ? "E-posta adresi:"
                    : "E-posta adresleri:"}

                  {data?.mail?.map((mail, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <TextField
                        sx={{ width: "80%", marginTop: "2%" }}
                        value={mail}
                        label={`E-posta ${index + 1}`}
                        onChange={(e) =>
                          handleChangeField("mail", index, e.target.value)
                        }
                        size="small"
                      />
                      {index > 0 && (
                        <IconButton
                          onClick={() => handleRemoveField("mail", index)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  ))}
                  <Tooltip title="E-posta eklemek için tıklayın">
                    <AddIcon
                      onClick={() => handleAddField("mail")}
                      size="small"
                      sx={{ marginLeft: "4vw" }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Save and Back Buttons */}
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", mb: 2 }}
      >
        <Button
          sx={{
            textTransform: "none",
            color: darken(0.2, "#5cb85c"),
            backgroundColor: lighten(0.3, "#5cb85c"),
            "&:hover": {
              backgroundColor: lighten(0.2, "#5cb85c"),
              color: darken(0.3, "#5cb85c"),
            },
          }}
          variant="contained"
          onClick={() => handleSave()}
        >
          <SaveIcon />
          Kaydet
        </Button>
      </Grid>
    </Grid>
  );
};

export default TaxPayerDetailsModal;
