import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Collapse,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import { motion } from "framer-motion";
import {
  Settings,
  Shield,
  BarChart,
  Plus,
  Minus,
  Download,
  RefreshCcw,
  UserRoundPlus,
} from "lucide-react";
import CloudIcon from "@mui/icons-material/Cloud";

// Animasyon tanımı
const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

// Bulutların yukarı-aşağı hareketi için animasyon tanımı
const floatClouds = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "15px",
        transition: "0.3s",
        zIndex: 1,
        "&:hover": {
          animation: `${float} 3s ease-in-out infinite`,
          boxShadow: "0 8px 24px rgba(0,0,0,0.15)",
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Icon size={48} color="#6a11cb" />
        </Box>
        <Typography variant="h5" component="div" gutterBottom align="center">
          {title}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          align="center"
          sx={{ fontSize: "18px" }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  </motion.div>
);

const FaqItem = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        mb: 2,
        borderRadius: "12px",
        overflow: "hidden",
        textTransform: "none", // Sadece istediğiniz harflerin büyük olmasını sağlar

        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        border: "1px solid #e0e0e0",
        transition: "box-shadow 0.3s, border-color 0.3s",
        "&:hover": {
          boxShadow: "0 6px 10px rgba(0, 0, 0, 0.15)",
          borderColor: "#d0d0d0",
        },
      }}
    >
      <Button
        onClick={() => setOpen(!open)}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 20px",
          background: "#f9f9f9",
          border: "none",
          textTransform: "none", // Sadece istediğiniz harflerin büyük olmasını sağlar

          borderBottom: "1px solid #e0e0e0",
          textAlign: "left",
          fontWeight: "500",
          fontSize: "16px",
          color: "#333",
          cursor: "pointer",
          transition: "background 0.3s, color 0.3s",
          "&:hover": {
            background: "#f0f0f0",
            color: "#000",
          },
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{question}</Box>
        {open ? (
          <Minus size={20} color="#007BFF" />
        ) : (
          <Plus size={20} color="#007BFF" />
        )}
      </Button>
      <Collapse
        in={open}
        timeout="auto"
        sx={{ transition: "opacity 0.3s ease" }}
      >
        <Box sx={{ padding: "16px 20px", background: "#fafafa" }}>
          <Typography variant="body1" sx={{ color: "#555" }}>
            {answer}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

const SaklamaLanding = () => {
  const arcadeDemoRef = useRef(null);

  const [clouds, setClouds] = useState([]);

  useEffect(() => {
    const numClouds = 15; // Kaç tane bulut olacağını ayarlayın
    const newClouds = [];
    for (let i = 0; i < numClouds; i++) {
      newClouds.push({
        top: `${Math.random() * 100}vh`,
        left: `${Math.random() * 100}vw`,
        size: `${Math.random() * 50 + 30}px`, // Bulut boyutu
        animationDelay: `${Math.random() * 5}s`, // Animasyon gecikmesi
        zIndex: 10,
      });
    }
    setClouds(newClouds);
  }, []);

  // "Denemek İçin Tıkla" butonu için scroll işlevi
  const scrollToDemoSection = () => {
    if (arcadeDemoRef.current) {
      arcadeDemoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          background: "linear-gradient(to right, #6a11cb, #2575fc)",
          color: "#fff",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {clouds.map((cloud, index) => (
          <CloudIcon
            key={index}
            style={{
              position: "absolute",
              top: cloud.top,
              left: cloud.left,
              fontSize: cloud.size,
              zIndex: cloud.zIndex,
              color: "rgba(255, 255, 255, 0.8)", // Bulut rengini beyaz yapın
              animation: `${floatClouds} 3s ease-in-out infinite`, // Animasyonu düzeltildi
            }}
          />
        ))}

        <Grid
          item
          md={12}
          xs={12}
          sx={{ display: "flex", marginTop: { xs: "20%", md: "2px" } }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "1200px",
              margin: "0 auto",
              padding: "0 20px",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box sx={{ flex: 1, textAlign: "left" }}>
              <Typography
                variant="body2"
                sx={{
                  display: "inline-block",
                  bgcolor: "rgba(255, 255, 255, 0.2)",
                  padding: "5px 10px",
                  borderRadius: "20px",
                  marginBottom: "20px",
                }}
              >
                RAHAT SAKLAMA KULLANIN RAHATINIZA BAKIN
              </Typography>

              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", md: "3rem" },
                }}
              >
                Rahat SAKLAMA ile defterlerinizi saklayın.
              </Typography>
              <Typography
                variant="h3"
                gutterBottom
                sx={{ fontWeight: "400", fontSize: { xs: "1rem", md: "2rem" } }}
              >
                e-Defterlerinizi güvenli bulut sunucularımızda saklayın, her
                yerden kolayca erişim sağlayın.
              </Typography>
              <Button
                component={motion.div} // Motion özelliklerini butona ekliyoruz
                animate={{ scale: [1, 1.05, 1] }} // Butonun büyüyüp küçülme animasyonu
                transition={{ duration: 1.5, repeat: Infinity }} // Sürekli tekrar eden animasyon
                variant="contained"
                size="large"
                onClick={() => {
                  window.location.href =
                    "https://saklama.rahatsistem.com.tr/auth/login";
                }}
                sx={{
                  mt: 2,
                  borderRadius: "24px",
                  bgcolor: "#ffd700",
                  color: "rgba(0, 0, 0, 0.8)", // Yarı transparan siyah renk
                  "&:hover": {
                    bgcolor: "#e6c200",
                  },
                  fontWeight: "bold",
                }}
              >
                PANELE GİRİŞ
              </Button>
            </Box>

            <Box
              sx={{
                flex: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "right",
                alignItems: "center",
                animation: `${float} 3s ease-in-out infinite`,
              }}
            >
              <img
                src="/images/cloud.png"
                alt="Heros Pages"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </Box>
          </Box>

          <Button
            variant="contained"
            size="medium"
            sx={{
              position: "absolute",
              textTransform: "none", // Sadece istediğiniz harflerin büyük olmasını sağlar

              top: "30px",
              right: "140px",
              bgcolor: "#ffff",
              borderRadius: "24px",
              color: "#000",
              "&:hover": {
                bgcolor: "#7367f0",
                color: "#ffff",
              },
            }}
            onClick={() =>
              (window.location.href =
                "https://saklama.rahatsistem.com.tr/auth/login")
            }
          >
            Giriş Yap
          </Button>
          <Button
            variant="contained"
            size="medium"
            component={motion.div} // Motion özelliklerini butona ekliyoruz
            animate={{ scale: [1, 1.05, 1] }} // Butonun büyüyüp küçülme animasyonu
            transition={{ duration: 1.5, repeat: Infinity }} // Sürekli tekrar eden animasyon
            sx={{
              position: "absolute",
              textTransform: "none", // Sadece istediğiniz harflerin büyük olmasını sağlar

              top: "30px",
              right: "50px",
              bgcolor: "#7367f0",
              borderRadius: "24px",
              color: "#ffff",
              "&:hover": {
                bgcolor: "#ffff",
                color: "#000",
              },
            }}
            onClick={() =>
              (window.location.href =
                "https://saklama.rahatsistem.com.tr/auth/register")
            }
          >
            Kayıt Ol
          </Button>
        </Grid>

        {/* Banner Kart  */}
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            marginBottom: "3%",
            marginTop: { xs: "10px", md: "0" },
          }}
        >
          <Grid item xs={10} md={3}>
            <FeatureCard
              icon={UserRoundPlus}
              title="Hesap Oluştur"
              description="Gerekli tüm bilgileri doğru ve eksiksiz bir şekilde girerek hesap oluşturun."
            />
          </Grid>

          <Grid item xs={10} md={3}>
            <FeatureCard
              icon={Download}
              title="Yerel Uygulamayı İndir"
              description="Yerel uygulamayı kurarak, tanımlamalar bölümünde gerekli ayarları yapın."
            />
          </Grid>

          <Grid item xs={10} md={3}>
            <FeatureCard
              icon={RefreshCcw}
              title="Senkronize Et"
              description='" Yerel Uygulama Senkronizasyonunu Başlat " butonuna tıklayarak defterlerinizi yükleyin.'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ background: "linear-gradient(to right, #6a11cb, #2575fc)" }}
      >
        {/* ARCADE - DEMO Section */}
        {/* <Grid
          container
          spacing={2}
          ref={arcadeDemoRef} // Arcade demo alanına referans
          sx={{
            height: "100vh",

            display: { xs: "none", md: "flex" },
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <iframe
                src="https://demo.arcade.software/gqG1od2hl4RWWs5eivsM?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
                title="Arcade Demo"
                style={{
                  width: "85%",
                  height: "85%",
                  border: "none",
                }}
              />
            </Box>
          </Grid>
        </Grid> */}
      </Grid>
      {/* Content Section */}
      <Grid container sx={{ bgcolor: "#f5f5f5" }}>
        <Grid
          item
          xs={12} // Butonun tam genişlikte yer kaplamasını sağlıyor
        >
          {/* Content Section */}
          <Grid
            container
            sx={{
              pb: 2,
              mb: 3,
              background: "linear-gradient(to right, #6a11cb, #2575fc)",
              justifyContent: "center",
            }}
          >
            <Button
              component={motion.div} // Motion özelliklerini butona ekliyoruz
              animate={{ scale: [1, 1.05, 1] }} // Butonun büyüyüp küçülme animasyonu
              transition={{ duration: 1.5, repeat: Infinity }} // Sürekli tekrar eden animasyon
              variant="contained"
              size="large"
              sx={{
                width: "300px", // Butonun genişliğini artırıyoruz
                height: "70px", // Butonun yüksekliğini artırıyoruz
                mt: 2,
                borderRadius: "24px",
                bgcolor: "#ffd700",
                color: "#fff",
                "&:hover": {
                  bgcolor: "#e6c200",
                },
                fontWeight: "bold",
                fontSize: "18px", // Buton yazı boyutunu da artırıyoruz
              }}
              onClick={() =>
                (window.location.href =
                  "https://saklama.rahatsistem.com.tr/auth/register")
              }
            >
              Kayıt Olmak İçin Tıkla !
            </Button>
          </Grid>

          <Grid item>
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "3%",
                marginTop: { xs: "10px", md: "0" },
              }}
            >
              {/* Features Section */}
              <Grid item xs={10} md={3}>
                <FeatureCard
                  icon={Settings}
                  title="Kolay Kullanım"
                  description="Kullanıcı dostu arayüz ile defter saklama ve görüntüleme işlemlerinizi hızlı ve basit bir şekilde gerçekleştirin."
                />
              </Grid>

              <Grid item xs={10} md={3}>
                <FeatureCard
                  icon={Shield}
                  title="Yüksek Güvenlik"
                  description="Verilerinizin güvenliğini ön planda tutarak, güçlü güvenlik önlemleri ile koruma sağlar."
                />
              </Grid>

              <Grid item xs={10} md={3}>
                <FeatureCard
                  icon={BarChart}
                  title="Kolay Erişim"
                  description="Her yerden kolayca erişebilmeniz için mobil uyumlu ve web tabanlı bir platform sunar."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* FAQ Section */}
        <Grid
          container
          spacing={3}
          sx={{ mt: 6, alignItems: "center", marginLeft: "5px" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ paddingRight: { md: "60px", xs: "30px" } }}
          >
            <Typography
              variant="h4"
              component="h2"
              sx={{ mb: 4, textAlign: "center" }}
            >
              Sıkça Sorulan Sorular
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FaqItem
                  question="Rahat Saklama ne işe yarar ?"
                  answer="Rahat saklama ile defterlerinizi kolay ve güvenli bir şekilde saklayın, bu sayede yasal gerekliliklere tam uyum sağlayarak verilerinizi her an erişilebilir ve güvenli tutun. İster küçük işletme olun ister büyük bir şirket, arşivleme ve saklama süreçlerinizi modern teknoloji ile bir araya getirerek iş süreçlerinizi daha verimli ve sorunsuz bir hale getirin."
                />
              </Grid>
              <Grid item xs={12}>
                <FaqItem
                  question="e-Defter'lerim güvende mi?"
                  answer="Tüm verileriniz ve e-Defter'leriniz en ileri düzeyde güvenlik protokolleriyle korunur ve uçtan uca şifreleme teknolojisiyle güvence altına alınır."
                />
              </Grid>
              <Grid item xs={12}>
                <FaqItem
                  question="Destek ekibi ile nasıl iletişime geçebilirim?"
                  answer="Destek ekibimize ulaşmak için, WhatsApp üzerinden 0542 315 8812 numarasına yazabilirsiniz."
                />
              </Grid>
              <Grid item xs={12}>
                <FaqItem
                  question="Ücretsiz deneme süresi var mı?"
                  answer="Evet, hemen ücretsiz denemeye başlamak için 0542 315 8812 numarasından bize ulaşabilirsiniz."
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
                animation: `${float} 3s ease-in-out infinite`,
              }}
            >
              <img
                src="/images/about-img.png"
                alt="FAQ Illustration"
                style={{
                  maxWidth: "50%",
                  height: "auto",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Footer Section */}
        <Grid item xs={12} sx={{ textAlign: "center", mt: 6 }}>
          <Typography variant="body2" color="textSecondary">
            © 2024 Rahat Sistem. Tüm Hakları Saklıdır.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SaklamaLanding;
