



import axios from "axios";
import CheckToken from "../../../src/utils/checkToken/CheckToken";

export const getRemoveTax = async (data) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    console.log(data)
    return await axios.put(`${process.env.REACT_APP_API_URL}/tax-payer/remove-taxes`, 
        {
            data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
);
};
