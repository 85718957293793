/** @format */

import {
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Button,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { lighten, darken } from "polished";
import Swal from "sweetalert2";
import { getDailyReportAsExcel } from "../../api/tax/getDailyReportAsExcel";
import moment from "moment/moment";
import "moment/locale/tr"; // Türkçe dil desteğini ekliyoruz

const ReportModal = ({ setReportModalOpen, }) => {
  const [selectedOption, setSelectedOption] = useState({
    tax: "D",
    taxType: [],
    period: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setSelectedOption((prevState) => {
      if (type === "checkbox") {
        const newTaxType = checked
          ? [...prevState.taxType, name]
          : prevState.taxType.filter((taxType) => taxType !== name);
        return { ...prevState, taxType: newTaxType, period: "" }; // TaxType değiştiğinde period'u sıfırla
      } else {
        // If tax value changes, reset taxType to an empty array
        if (name === "tax") {
          return { tax: value, taxType: [], period: "" }; // Tax değiştiğinde taxType ve period'u sıfırla
        } else if (name === "period") {
          return { ...prevState, [name]: value };
        }
        return { ...prevState, [name]: value };
      }
    });
  };

  const disabled = (name) => {
    if (selectedOption.tax === "M") {
      // Eğer tax "M" ise
      if (name === "3 AY" && selectedOption.taxType.includes("1 AY")) {
        return true; // 1 AY seçiliyse 3 AY switch'i devre dışı bırak
      }
      if (name === "1 AY" && selectedOption.taxType.includes("3 AY")) {
        return true; // 3 AY seçiliyse 1 AY switch'i devre dışı bırak
      }
    }
    return false; // Devre dışı bırakma yoksa false döndür
  };

  const getPeriodOptions1year = () => {
    const periods = [];
    let currentYear = moment().year(); // Bu yılın sayısını alın

    for (let i = 1; i < 3; i++) {
      // Yıl aralığını oluşturup periods dizisine ekleyin
      const year = currentYear - i;
      periods.push(`01/${year} - 12/${year}`);
    }

    return periods;
  };

  const getPeriodOptions = () => {
    const periods = [];
    let currentDate = moment().subtract(1, "month"); // Bir önceki aydan başla

    // Geriye doğru 11 ayı ekle
    for (let i = 0; i < 11; i++) {
      periods.push(currentDate.format("MM/YYYY")); // Ayı ve yılı ekliyoruz
      currentDate = currentDate.subtract(1, "month"); // Bir önceki ayı işle
    }

    return periods;
  };
  const getPeriodOptions3month = () => {
    moment.locale("tr"); // Dil ayarını Türkçe olarak yapıyoruz

    const periods = [];
    let currentDate = moment(); // Şu anki tarih

    // Eğer bu ayın 1-3 ayları içindeysek sadece bu dilimi alalım
    if (currentDate.month() >= 0 && currentDate.month() <= 2) {
      const startMonth = currentDate
        .clone()
        .subtract(currentDate.month(), "months")
        .format("MM/YYYY");
      const endMonth = currentDate.format("MM/YYYY");
      periods.unshift(`${startMonth} - ${endMonth}`); // En yeni periyodu en başa ekliyoruz
    } else {
      // Değilse, bir önceki periyodu alalım
      currentDate.subtract((currentDate.month() % 3) + 1, "months"); // Bir önceki 3 aylık dilime git

      for (let i = 0; i < 4; i++) {
        const startMonth = currentDate
          .clone()
          .subtract(2, "months")
          .format("MM/YYYY");
        const endMonth = currentDate.format("MM/YYYY");
        periods.unshift(`${startMonth} - ${endMonth}`); // Her periyodu başa ekliyoruz
        currentDate.subtract(3, "months");
      }
    }

    return periods.reverse();
  };

  const handleDailyReportExcelDownload = async () => {
    console.log(selectedOption);

    try {
      await getDailyReportAsExcel(selectedOption)
        .then((resp) => {
          Swal.fire({
            title: resp.data,
            icon: "success",
            confirmButtonText: "Tamam",
          });
          setReportModalOpen(false);
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.message || "Hata oluştu",
            icon: "error",
            confirmButtonText: "Tamam",
          });
        });
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "70vh",
        width: "30vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          fontWeight="bold"
          sx={{ color: "#003366" }}
        >
          RAPOR İNDİR
        </Typography>
      </Grid>

      <Grid item sx={{ marginBottom: 3 }}>
        <Typography variant="h6">Vergi Tipi</Typography>
        <TextField
          fullWidth
          select
          label="VERGİ TÜRÜ SEÇİNİZ"
          onChange={handleChange}
          name="tax"
          size="medium"
          value={selectedOption.tax}
          sx={{ marginTop: 2 }}
        >
          <MenuItem value={"KveG"}>KURUMLAR ve GELİR</MenuItem>
          <MenuItem value={"K-G-GECICI"}>K.GEÇİCİ ve G.GEÇİCİ</MenuItem>
          <MenuItem value={"M"}>MUHSGK</MenuItem>
          <MenuItem value={"D"}>DİĞER VERGİLER</MenuItem>
        </TextField>
      </Grid>

      {selectedOption.tax !== "D" && selectedOption.tax !== "" && (
        <Grid item sx={{ marginBottom: 2 }}>
          <FormGroup row sx={{ justifyContent: "center" }}>
            <FormControlLabel
              control={
                <Switch
                  name={
                    selectedOption.tax === "KveG"
                      ? "KURUMLAR"
                      : selectedOption.tax === "K-G-GECICI"
                      ? "KGECICI"
                      : "1 AY"
                  }
                  checked={
                    selectedOption.tax === "KveG"
                      ? selectedOption.taxType.includes("KURUMLAR")
                      : selectedOption.tax === "K-G-GECICI"
                      ? selectedOption.taxType.includes("KGECICI")
                      : selectedOption.taxType.includes("1 AY")
                  }
                  onChange={handleChange}
                  disabled={disabled("1 AY")}
                />
              }
              label={
                selectedOption.tax === "KveG"
                  ? "KURUMLAR"
                  : selectedOption.tax === "K-G-GECICI"
                  ? "K.GEÇİCİ"
                  : "1 AY"
              }
            />
            <FormControlLabel
              control={
                <Switch
                  name={
                    selectedOption.tax === "KveG"
                      ? "GELIR"
                      : selectedOption.tax === "K-G-GECICI"
                      ? "GGECICI"
                      : "3 AY"
                  }
                  checked={
                    selectedOption.tax === "KveG"
                      ? selectedOption.taxType.includes("GELIR")
                      : selectedOption.tax === "K-G-GECICI"
                      ? selectedOption.taxType.includes("GGECICI")
                      : selectedOption.taxType.includes("3 AY")
                  }
                  onChange={handleChange}
                  disabled={disabled("3 AY")}
                />
              }
              label={
                selectedOption.tax === "KveG"
                  ? "GELİR"
                  : selectedOption.tax === "K-G-GECICI"
                  ? "G.GEÇİCİ"
                  : "3 AY"
              }
            />
          </FormGroup>
        </Grid>
      )}

      <Grid item sx={{ marginBottom: 3 }}>
        <Typography variant="h6">Dönem</Typography>
        <TextField
          fullWidth
          select
          label="Dönem SEÇİNİZ"
          onChange={handleChange}
          name="period"
          size="medium"
          value={selectedOption.period}
          sx={{ marginTop: 2 }}
        >
          {selectedOption.tax === "K-G-GECICI" ||
          (selectedOption.tax === "M" &&
            selectedOption.taxType.includes("3 AY"))
            ? getPeriodOptions3month().map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))
            : selectedOption.tax === "KveG"
            ? getPeriodOptions1year().map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))
            : getPeriodOptions().map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
        </TextField>
      </Grid>

      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleDailyReportExcelDownload}
          disabled={
            selectedOption.tax !== "D"
              ? selectedOption.tax === "" ||
                selectedOption.taxType.length < 1 ||
                selectedOption.period === ""
              : selectedOption.tax === "" || selectedOption.period === ""
          }
          sx={{
            textTransform: "none",
            borderRadius: 2,
            paddingX: 4,
            paddingY: 1.5,
            fontSize: "1rem",
            width: "30%",
          }}
        >
          İndir
        </Button>
      </Grid>
    </Grid>
  );
};

export default ReportModal;
