import { useState, useCallback, useMemo, useEffect } from "react";
import AdminSidebar from "../../components/adminSideBar/adminSideBar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import Swal from "sweetalert2";
import date from "date-and-time";
import CreateUserModal from "./createUserModal";
import UserDetailModal from "./userDetailModal";

import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EditIcon from "@mui/icons-material/Edit";

import { darken, lighten } from "polished";

import {
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Pagination,
  MenuItem,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";
import { createColumnDefs } from "./columnDefs.js";
import { getUserList } from "../../api/user/getUserList";
import TaxTypeModal from "./taxTypeModal";
import localeTextTr from "../../locale.tr";
import { FileInfo } from "tabler-icons-react";
import localStorage from "local-storage";
import { getRunningJobs } from "../../api/queue/getRunningJobs";

function AdminUsers() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    console.log(isOpen);
  };
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openTaxTypesModal, setOpenTaxTypesModal] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [lastScroll, setLastScroll] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [openUserDetailModal, setOpenUserDetailModal] = useState(false);
  const [searchedValue, setSearchedValue] = useState(null);
  const [reload, setReload] = useState(false);
  const [sort, setSort] = useState({ name: null, sort: null });
  const [filters, setFilters] = useState({
    status: null,
    types: [],
    taxDeclared: [],
    DeclarationStatus: null,
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const search = (value) => {
    getUserList(
      currentPage,
      pageSize,
      value || searchedValue,
      sort,
      filters,
      startDate ? date.format(new Date(startDate), "YYYY-MM-DD") : null,
      endDate ? date.format(new Date(endDate), "YYYY-MM-DD") : null
    )
      .then((resp) => {
        setUsers(resp.data.users);
        setTotalPage(resp.data.totalPage);
        setTotalCount(resp.data.totalUsersCount);
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
        });
      });
  };

  useEffect(() => {
    const lastPage = localStorage.get("page");
    const lastPageSize = localStorage.get("pageSize");
    const sideBarOpen = localStorage.get("sidebar");
    search();
    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    if (lastPage) {
      setCurrentPage(lastPage);
    }
    if (lastPageSize) {
      setPageSize(lastPageSize);
    }
    const localScroll = localStorage.get("scrollPosition");
    if (localScroll) {
      setLastScroll(localScroll);
    }

    const cleanupLocalStorage = () => {
      localStorage.clear();
    };
    window.addEventListener("beforeunload", cleanupLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", cleanupLocalStorage);
    };
  }, [reload, sort]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    }),
    []
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const localeText = {
    // Set locale text here
    columns: "Kolonlar",
    filters: "Filtreler",
    // Other translations
    selectAll: "(Hepsini Seç)",
    selectAllSearchResults: "(Tüm Arama Sonuçlarını Seç)",
    searchOoo: "Arama...",
    blanks: "Boşluklar",
    noMatches: "Eşleşme Yok",
    // Add more as needed
  };
  const [columnDefs, setColumnDefs] = useState(
    createColumnDefs(setSelectedUser, setOpenUserDetailModal)
  );

  const [rowData, setRowData] = useState();

  // Örnek veri

  const buttonRenderer = (params) => {
    return (
      <Button onClick={() => console.log("Detay tıklandı:", params.data)}>
        Detay
      </Button>
    );
  };

  const onGridReady = useCallback((params) => {
    setRowData(users);
  }, []);
  return (
    <Grid container>
      <Grid item md={isOpen ? 1.8 : 0.7}>
        <AdminSidebar
          status={isOpen}
          toggleSidebar={toggleSidebar}
          location={"homePage"}
        />
      </Grid>
      <Grid
        item
        md={isOpen ? 10.2 : 11.3}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          pr: "4vh",
          gap: 1,
        }}
      >
        <Grid
          md={12}
          className="grid-area"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid md={12} style={{ display: "flex", flexDirection: "column" }}>
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2%",
              }}
            >
              <Typography variant="h4">Kullanıcılar</Typography>
            </Grid>

            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5%",
              }}
            >
              <Grid item md={3}>
                <Grid
                  item
                  md={12}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <TextField
                    className="input-field"
                    placeholder="Ara...(vkn/tckn,ünvan,e-posta)"
                  />
                  <IconButton className="date-button">
                    <SearchOutlinedIcon fontSize="small" color="white" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                md={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  justifyContent: "end",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                  variant="contained"
                  onClick={() =>
                    getRunningJobs()
                      .then((resp) => {
                        if (resp.data.total > 0) {
                          Swal.fire({
                            title: `Devam eden ${resp.data.total} senkronizasyon var`,
                            icon: "info",
                          });
                        } else {
                          Swal.fire({
                            title: `Devam eden senkronizasyon yok`,
                            icon: "info",
                          });
                        }
                      })
                      .catch((err) =>
                        Swal.fire({
                          title: err.response.data.message,
                          icon: "info",
                        })
                      )
                  }
                >
                  Devam eden senk. kontrol et
                </Button>

                <Button
                  className="admin-button"
                  sx={{
                    textTransform: "none",
                    alignItems: "center",
                    marginLeft: "2%",
                    backgroundColor: lighten(0.4, "orange"),
                    color: "orange",
                    gap: 1,
                    "&:hover": {
                      backgroundColor: lighten(0.3, "orange"),
                      borderColor: "#d9534f",
                    },
                  }}
                  variant="contained"
                  onClick={() => setOpenTaxTypesModal(true)}
                >
                  <EditIcon />
                  Vergileri yönet{" "}
                </Button>

                <Button
                  sx={{
                    textTransform: "none",
                    alignItems: "center",
                    backgroundColor: lighten(0.6, "green"),
                    marginLeft: "1%",
                    color: "green",
                    gap: 1,
                    "&:hover": {
                      backgroundColor: lighten(0.5, "green"),
                      borderColor: "#d9534f",
                    },
                  }}
                  className="admin-button"
                  onClick={() => setOpenCreateUserModal(true)}
                >
                  <PersonAddAltIcon />
                  Kullanıcı Ekle
                </Button>
              </Grid>
            </Grid>
            <Grid
              className="ag-theme-quartz"
              style={{ minHeight: "70vh", width: "100%" }}
            >
              <AgGridReact
                rowSelection="multiple"
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={users}
                sideBar={gridSideBar}
                localeText={localeTextTr}
                paginationPageSize={10}
                onGridReady={onGridReady}
                frameworkComponents={{
                  buttonRenderer: buttonRenderer,
                }}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: 1,
                justifyContent: "space-between",
              }}
            >
              <Grid sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Pagination
                  count={totalPage}
                  showFirstButton
                  showLastButton
                  page={currentPage}
                  onChange={(event, value) => {
                    setCurrentPage(value);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    localStorage.set("page", value);
                  }}
                />
                <TextField
                  select
                  value={pageSize}
                  size="small"
                  sx={{
                    display:
                      rowData && rowData?.length === 0 ? "none" : "block",
                  }}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setPageSize(e.target.value);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    localStorage.set("page", 1);
                    localStorage.set("pageSize", e.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={200}>250</MenuItem>
                </TextField>
              </Grid>
              <TaxTypeModal
                openModal={openTaxTypesModal}
                setOpenModal={setOpenTaxTypesModal}
                setReload={setReload}
                reload={reload}
              />
              <UserDetailModal
                reload={reload}
                setOpenUserDetailModal={setOpenUserDetailModal}
                setReload={setReload}
                detailData={selectedUser}
                openUserDetailModal={openUserDetailModal}
              />
              <CreateUserModal
                setOpenCreateUserModal={setOpenCreateUserModal}
                openCreateUserModal={openCreateUserModal}
                setReload={setReload}
                reload={reload}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdminUsers;
