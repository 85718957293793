/** @format */

// Düzeltildi

import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { handleUnDeclaratedTaxes } from "../../api/tax/handleUnDeclaratedTaxes";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { lighten, darken } from "polished";
import { getUndeclaratedTaxesHistory } from "../../api/tax/getUndeclaratedTaxesHistory";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import Swal from "sweetalert2";
import date from "date-and-time";
const UnDeclaratedTaxesModal = ({ data, setReload, reload }) => {
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [selectedTaxesOfHistory, setSelectedTaxesOfHistory] = useState([]);
  const [note, setNote] = useState("");
  const [history, setHistory] = useState([]);
  useEffect(() => {
    getUndeclaratedTaxesHistory(data.id)
      .then((resp) => setHistory(resp.data.history))
      .catch((err) =>
        Swal.fire({
          icon: "warning",
          title: err.response.data.message,
          confirmButtonText: "Tamam",
        })
      );
  }, [reload]);
  const columnDefs = [
    {
      field: "tax",
      flex: 1,
      headerName: "Vergi",
      cellStyle: { textAlign: "start" },
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "process",
      flex: 1,
      headerName: "İşlem",
      cellStyle: { textAlign: "start" },
    },
    {
      field: "taxPeriod",
      flex: 1,
      headerName: "Dönem",
      cellStyle: { textAlign: "start" },
    },
    {
      field: "createdAt",
      flex: 1,
      headerName: "Oluşturulma tarihi",
      cellStyle: { textAlign: "start" },
      valueGetter: (props) =>
        date.format(
          new Date(props.data.createdAt),
          "HH:mm:ss DD-MM-YYYY",
          false
        ),
    },
    {
      field: "updated_at",
      flex: 1,
      headerName: "Güncelleme tarihi",
      cellStyle: { textAlign: "start" },
      valueGetter: (props) =>
        date.format(
          new Date(props.data.updated_at),
          "HH:mm:ss DD-MM-YYYY",
          false
        ),
    },
    {
      field: "note",
      flex: 1,
      headerName: "Not",
      cellStyle: { textAlign: "start" },
    },
  ];
  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedIds = selectedNodes.map((node) => node.data.tax);
    setSelectedTaxesOfHistory(selectedIds);
  };
  const handleMarkAsSent = () => {
    const date = new Date();
    let month = date.getMonth();
    month = month === 0 ? 12 : month;

    const year = month === 12 ? date.getFullYear() - 1 : date.getFullYear();

    const period = `${month}/${year}`;

    handleUnDeclaratedTaxes(
      data.id,
      period,
      "Verildi olarak işaretlendi",
      selectedTaxes,
      note
    )
      .then((resp) => {
        setReload(!reload);
        setNote("");
        setSelectedTaxes([]);
        Swal.fire({
          icon: "success",
          title: resp.data.message,
          confirmButtonText: "Tamam",
        });
      })
      .catch((err) => {
        setReload(!reload);
        setNote("");
        setSelectedTaxes([]);
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          confirmButtonText: "Tamam",
        });
      });
  };
  const handleMarkAsNotSent = () => {
    const date = new Date();
    let month = date.getMonth();
    month = month === 0 ? 12 : month;

    const year = month === 12 ? date.getFullYear() - 1 : date.getFullYear();

    const period = `${month}/${year}`;
    handleUnDeclaratedTaxes(
      data.id,
      period,
      "Verilmedi olarak işaretlendi",
      selectedTaxesOfHistory,
      note
    )
      .then((resp) => {
        setReload(!reload);
        setNote("");
        setSelectedTaxesOfHistory([]);
        Swal.fire({
          title: resp.data.message,
          confirmButtonText: "Tamam",
        });
      })
      .catch((err) => {
        setReload(!reload);
        setNote("");
        setSelectedTaxesOfHistory([]);
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          confirmButtonText: "Tamam",
        });
      });
  };
  const isRowSelectable = useMemo(() => {
    return (rowNode) => {
      return rowNode.data
        ? rowNode.data.isValid && rowNode.data.process !== "Verilmedi"
        : false;
    };
  }, []);
  return (
    <Grid container sx={{ height: "70vh" }}>
      <Grid item xs={12}>
        <Typography variant="h4" textAlign={"center"}>
          {" "}
          Beyannamesi Verilmemiş Vergiler
        </Typography>{" "}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", gap: 1, flexDirection: "column" }}
      >
        <Autocomplete
          fullWidth
          value={selectedTaxes}
          onChange={(event, newValue) => {
            setSelectedTaxes(newValue);
          }}
          id="controllable-states-demo"
          options={data.unDeclaratedTaxes}
          multiple={true}
          renderInput={(params) => <TextField {...params} label="Vergi Seç" />}
          noOptionsText="Verilmemiş beyanname yok"
        />
        <TextField
          fullWidth
          label="Not giriniz(opsiyonel)"
          onBlur={(e) => setNote(e.target.value)}
          multiline={true}
          sx={{ marginTop: "2%" }}
          helperText={
            selectedTaxesOfHistory.length !== 0
              ? "Buraya not girebilirsiniz"
              : ""
          }
        />
        <Button
          onClick={() => handleMarkAsSent()}
          sx={{
            textTransform: "none",
            fontWeight: "700",
            color: lighten(0.1, "green"),
            backgroundColor: lighten(0.7, "green"),
            alignSelf: "center",
            "&:hover": {
              backgroundColor: lighten(0.6, "green"),
            },
          }}
          variant="contained"
          startIcon={
            <BookmarkAddedIcon
              sx={{
                color:
                  selectedTaxes.length === 0 ? "gray" : lighten(0.1, "green"),
              }}
            />
          }
          disabled={selectedTaxes.length === 0}
        >
          Verildi olarak işaretle
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <Typography variant="h4" textAlign={"center"} marginBottom={"2%"}>
            {" "}
            Geçmiş
          </Typography>{" "}
          <Button
            sx={{
              textTransform: "none",
              color: "red",
              backgroundColor: lighten(0.4, "red"),
              marginLeft: "auto",
              fontWeight: "700",
              "&:hover": {
                backgroundColor: lighten(0.3, "red"),
                color: darken(0.1, "red"),
              },
            }}
            onClick={() => handleMarkAsNotSent()}
            variant="contained"
            disabled={selectedTaxesOfHistory.length === 0}
            startIcon={
              <BookmarkRemoveIcon
                sx={{
                  color: selectedTaxesOfHistory.length === 0 ? "gray" : "red",
                }}
              />
            }
          >
            Seçilen işlemi Verilmedi olarak işaretle
          </Button>
        </Grid>

        <div
          className="ag-theme-quartz"
          style={{
            flex: 1,
            minHeight: "10vh",
            width: "100%",
            marginBottom: "3%",
          }}
        >
          <AgGridReact
            style={{ flex: 1, width: "100%" }}
            rowData={history}
            columnDefs={columnDefs}
            gridOptions={{
              suppressCellFocus: true,
            }}
            enableCellTextSelection={true}
            rowSelection={"single"}
            domLayout="autoHeight"
            isRowSelectable={isRowSelectable}
            rowMultiSelectWithClick={true}
            overlayNoRowsTemplate={
              "Gösterilecek beyannamesi verilmemiş vergi geçmişi yok"
            }
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default UnDeclaratedTaxesModal;
