import Swal from "sweetalert2";

// Yükleniyor Swal bileşeni
export const showLoadingSwal = (textContent) => {
  Swal.fire({
    title: "Lütfen Bekleyiniz",
    text: textContent ? textContent : "Yanıt bekleniyor",
    icon: "info",
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    customClass: {
      popup: "swal2-content-centered",
    },
    didOpen: () => {
      document.querySelector(".swal2-container").style.zIndex = "9999";
    },
    willOpen: () => {
      Swal.showLoading();
    },
  });
};
