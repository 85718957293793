/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const getDeclarationMailhistory = async (data) => {
  const token = await CheckToken();
  if (!token) {
    // Redirect to login page
    window.location.href = "/login";
  }

  return await axios.get(
    `${process.env.REACT_APP_API_URL}/declaration/get-declaration-history`,
    {
      params: {
        declarationId: data[0],
        communicationType: data[1],
      },

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
