import { useState, useEffect } from "react";
import Sidebar from "../../components/sideBar/sideBar";
import Navbar from "../../components/navbar/navbar";
import localStorage from "local-storage";
import { Doughnut, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { getTaxPayerList } from "../../api/taxPayer/getTaxPayerList";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Grid,
  Box,
  Card,
  Typography,
  CardContent,
  Divider,
} from "@mui/material";
import { darken } from "polished";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

function HomePage() {
  const [isOpen, setIsOpen] = useState(false);
  const [taxPayerList, setTaxPayerList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [kdv1DeclaratedTaxPayes, setKdv1DeclaratedTaxPayes] = useState(0);
  const [kdv2DeclaratedTaxPayes, setKdv2DeclaratedTaxPayes] = useState(0);
  const [muhsgkDeclaratedTaxPayes, setMuhsgkDeclaratedTaxPayes] = useState(0);

  useEffect(() => {
    // Aktif & Pasif Mükellef sayısı için atılan istek.
    getTaxPayerList(1, 100).then((resp) => {
      if (resp.data.totalCount > 100) {
        getTaxPayerList(1, resp.data.totalCount).then((resp2) => {
          setTaxPayerList(resp2.data.data);
          setTotalCount(resp2.data.totalCount);
          getDeclaratedTaxPayers(resp2.data.totalCount);
        });
      } else {
        setTaxPayerList(resp.data.data);
        setTotalCount(resp.data.totalCount);
        getDeclaratedTaxPayers(resp.data.totalCount);
      }
    });
    const getDeclaratedTaxPayers = (totalTaxPayerCount) => {
      getTaxPayerList(1, totalTaxPayerCount, undefined, undefined, {
        declarationStatus: true,
        declarationOperator: "AND",
        isDeclarationTypes: ["KDV1"],
        periodMonth: moment().subtract(1, "month").format("MM"),
        periodYear: moment().year(),
        startDate:
          moment().subtract(1, "month").startOf("month").format("DD/MM/YYYY") ||
          null,
        endDate:
          moment().subtract(1, "month").endOf("month").format("DD/MM/YYYY") ||
          null,
        rangeIsPeriod: true,
      }).then((resp) => {
        setKdv1DeclaratedTaxPayes(resp.data.totalCount);
      });
      getTaxPayerList(1, totalTaxPayerCount, undefined, undefined, {
        declarationStatus: true,
        declarationOperator: "AND",
        isDeclarationTypes: ["KDV2"],
        periodMonth: moment().subtract(1, "months").format("MM"),
        periodYear: moment().year(),
        startDate:
          moment().subtract(1, "month").startOf("month").format("DD/MM/YYYY") ||
          null,
        endDate:
          moment().subtract(1, "month").endOf("month").format("DD/MM/YYYY") ||
          null,
        rangeIsPeriod: true,
      }).then((resp) => {
        setKdv2DeclaratedTaxPayes(resp.data.totalCount);
      });
      getTaxPayerList(1, totalTaxPayerCount, undefined, undefined, {
        declarationStatus: true,
        declarationOperator: "AND",
        isDeclarationTypes: ["MUHSGK"],
        periodMonth: moment().subtract(1, "months").format("MM"),
        periodYear: moment().year(),
        startDate:
          moment().subtract(1, "month").startOf("month").format("DD/MM/YYYY") ||
          null,
        endDate:
          moment().subtract(1, "month").endOf("month").format("DD/MM/YYYY") ||
          null,
        rangeIsPeriod: true,
      }).then((resp) => {
        setMuhsgkDeclaratedTaxPayes(resp.data.totalCount);
      });
    };

    const sideBarOpen = localStorage.get("sidebar");

    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }

    const cleanupLocalStorage = () => {
      localStorage.clear();
    };
    window.addEventListener("beforeunload", cleanupLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", cleanupLocalStorage);
    };
  }, []);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    console.log(isOpen);
  };

  let statusData = {
    labels: ["Aktif Mükellefler", "Pasif Mükellefler"],
    datasets: [
      {
        label: "",
        data: [
          taxPayerList.filter((taxPayer) => taxPayer.is_deleted === false)
            .length,
          taxPayerList.filter((taxPayer) => taxPayer.is_deleted === true)
            .length,
        ], // Toplam mükellef sayısı üzerinden aktif ve pasif sayılar
        backgroundColor: ["#6EC207", darken(0.02, "#FE0000")], // Uygun ton yeşil ve kırmızı
        hoverOffset: 5,
        borderColor: ["#FFFF", "#FFFF"],
        borderWidth: 1,
      },
    ],
  };

  const statusOptions = {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let total = ctx.chart.data.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );

          return `${value}`;
        },
        color: "#fff",
        font: {
          weight: "400",
          size: 16,
        },
      },
    },
    responsive: true,
  };

  const taxData = {
    labels: ["KDV1 ", "KDV2 ", "MUHSGK "],
    datasets: [
      {
        label: "Beyannamesi Verilmiş Mükellefler",
        data: [
          kdv1DeclaratedTaxPayes,
          kdv2DeclaratedTaxPayes,
          muhsgkDeclaratedTaxPayes,
        ],
        backgroundColor: ["#6EC207", "#6EC207", "#6EC207"],
        borderColor: ["#6EC207", "#6EC207", "#6EC207"],
        borderWidth: 1,
      },
      {
        label: "Beyannamesi Verilmemiş mükellefler",
        data: [
          taxPayerList.filter((taxPayer) => taxPayer.is_deleted === false)
            .length - kdv1DeclaratedTaxPayes,
          taxPayerList.filter((taxPayer) => taxPayer.is_deleted === false)
            .length - kdv2DeclaratedTaxPayes,
          taxPayerList.filter((taxPayer) => taxPayer.is_deleted === false)
            .length - muhsgkDeclaratedTaxPayes,
        ],
        backgroundColor: [
          darken(0.02, "#FE0000"),
          darken(0.02, "#FE0000"),
          darken(0.02, "#FE0000"),
        ],
        borderColor: [
          darken(0.02, "#FE0000"),
          darken(0.02, "#FE0000"),
          darken(0.02, "#FE0000"),
        ],
        borderWidth: 1,
      },
    ],
  };

  const taxOptions = {
    plugins: {
      datalabels: {
        formatter: (value) => {
          return value === 0 ? "" : `${value}`; // Eğer değer 0 ise etiketi gösterme
        },
        anchor: "start",
        align: "top",
        color: "#fff", // Yazı rengini beyaz yap

        font: {
          weight: "400",
          size: 16,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true, // X ekseninde yığılmış çubuklar
      },
      y: {
        stacked: true, // Y ekseninde yığılmış çubuklar
        max: taxPayerList.filter((taxPayer) => taxPayer.is_deleted === false)
          .length, // Y ekseni için maksimum değer
      },
    },
    barThickness: 50, // Her bir çubuğun sabit genişliği
    maxBarThickness: 80, // Çubukların maksimum genişliği
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease", // Düzeltilmiş transition
        }}
      >
        <Sidebar status={isOpen} toggleSidebar={toggleSidebar} />
      </Grid>

      <Grid
        item
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid item md={12}>
          <Navbar />
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            marginRight: "4vh",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Grid
            item
            md={12}
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            <Typography variant="h6">Müşavir Paneli</Typography>
            <Divider
              orientation="vertical"
              flexItem
              md={{ height: "5vh", my: 2 }}
            />

            <Typography variant="subtitle1">İstatistikler</Typography>
          </Grid>

          <Grid item md={12}>
            {/* <Alert
              severity="info"
              sx={{
                marginBottom: "2vh",
                borderRadius: "2vh",
                border: "1px solid #1232e4",
              }}
            >
              Mükellef verilerinizi görüntülemek için paneli kullanabilirsiniz.
            </Alert> */}
          </Grid>
          <Grid item xs={12}>
            <Grid container sx={{ justifyContent: "space-around" }}>
              <Grid item xs={12} md={4}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        height: "30vh",
                      }}
                    >
                      <Doughnut
                        data={statusData}
                        options={statusOptions}
                        plugins={[ChartDataLabels]}
                      />
                    </Box>
                  </CardContent>
                  <Typography
                    variant="body1"
                    align="center"
                    color="textSecondary"
                    sx={{ mb: 4 }}
                  >
                    Aktif ve Pasif mükelleflerin sayısını gösterir.
                  </Typography>
                </Card>
              </Grid>{" "}
              <Grid item xs={12} md={7}>
                <Card
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{}}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30vh",
                      }}
                    >
                      <Bar
                        data={taxData}
                        options={taxOptions}
                        plugins={[ChartDataLabels]}
                      />
                    </Box>
                  </CardContent>
                  <Typography
                    variant="body1"
                    align="center"
                    color="textSecondary"
                    sx={{ mb: 4 }}
                  >
                    KDV1, KDV2 ve MUHSGK beyanname mükelleflerinin durumunu
                    gösterir.
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HomePage;
