import React from "react";
import { Grid, Typography, CircularProgress, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import Icon from "@mdi/react";
import { mdiFilePdfBox } from "@mdi/js";

const SgkListModal = ({ data, handlePdfClickForSgk, pdfLoading }) => {
  const columnDefs = [
    {
      field: "description",
      flex: 2,
      headerName: "Açıklama",
      cellStyle: { textAlign: "start" },
    },
    {
      field: "status",
      flex: 1,
      headerName: "Durum",
      cellStyle: { textAlign: "start" },
    },
    {
      field: "taxationPeriod",
      flex: 1,
      headerName: "Dönem",
      cellStyle: { textAlign: "start" },
    },
    {
      field: "rDriveAccrualID",
      flex: 1,
      headerName: "Tahakkuk",
      cellStyle: { textAlign: "start" },
      cellRenderer: (props) => (
        <div style={{ position: "relative" }}>
          <Tooltip
            title={
              props?.data?.rDriveAccrualID
                ? "Tahakkuku görmek için tıklayın"
                : "Sayfayı yenileyerek sisteme yüklenen tahakkukları görebilirsiniz."
            }
          >
            <span>
              <Icon
                path={mdiFilePdfBox}
                size={1.2}
                color="red"
                style={{
                  display: props?.data?.rDriveAccrualID ? "block" : "none",
                  cursor: props?.data?.rDriveAccrualID ? "pointer" : "",
                  width: "40px",
                }}
                onClick={() =>
                  handlePdfClickForSgk(props?.data?.rDriveAccrualID)
                }
              />
              <Typography
                sx={{
                  fontSize: "11px",
                  display: props?.data?.rDriveAccrualID ? "none" : "block",
                  marginTop: "5px", // Eklenen satır: Metnin altındaki boşluk
                }}
                onClick={() =>
                  Swal.fire({
                    text: "Bu beyannamenin tahakkuku yok",
                    icon: "warning",
                    confirmButtonText: "Tamam",
                    customClass: {
                      htmlContainer: ".swal2-container",
                    },
                  })
                }
              >
                {props?.data?.status === "Onaylandı"
                  ? "Tahhakuk sisteme yükleniyor..."
                  : "Tahakkuk yok"}
              </Typography>
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "rDriveServiceID",
      flex: 1,
      headerName: "Hizmet tahakkuku",
      cellStyle: { textAlign: "start" },
      cellRenderer: (props) => (
        <div style={{ position: "relative" }}>
          <Tooltip
            title={
              props?.data?.rDriveServiceID
                ? "Tahakkuku görmek için tıklayın"
                : "Sayfayı yenileyerek sisteme yüklenen tahakkukları görebilirsiniz."
            }
          >
            <span>
              <Icon
                path={mdiFilePdfBox}
                size={1.2}
                color="red"
                style={{
                  display: props?.data?.rDriveServiceID ? "block" : "none",
                  cursor: props?.data?.rDriveServiceID ? "pointer" : "",
                  width: "40px",
                }}
                onClick={() =>
                  handlePdfClickForSgk(props?.data?.rDriveServiceID)
                }
              />
              <Typography
                sx={{
                  fontSize: "11px",
                  display: props?.data?.rDriveServiceID ? "none" : "block",
                  marginTop: "5px", // Eklenen satır: Metnin altındaki boşluk
                }}
                onClick={() =>
                  Swal.fire({
                    text: "Bu beyannamenin tahakkuku yok",
                    icon: "warning",
                    confirmButtonText: "Tamam",
                    customClass: {
                      htmlContainer: ".swal2-container",
                    },
                  })
                }
              >
                {props?.data?.status === "Onaylandı"
                  ? "Tahhakuk sisteme yükleniyor..."
                  : "Tahakkuk yok"}
              </Typography>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Grid
      container
      sx={{
        width: "50vw",
        paddingTop: "5%",
        paddingBottom: "5%",
        gap: 2,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" textAlign={"center"}>
          SGK Beyannamaleri{" "}
        </Typography>{" "}
      </Grid>

      {pdfLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={64} />
            <Typography>Lütfen bekleyiniz</Typography>
          </Grid>
        </div>
      )}

      <div
        className="ag-theme-quartz"
        style={{ flex: 1, minHeight: "10vh", width: "100%" }}
      >
        <AgGridReact
          style={{ flex: 1, width: "100%" }}
          rowData={data.sgkAccrualsAndServices}
          columnDefs={columnDefs}
          gridOptions={{
            suppressCellFocus: true,
          }}
          domLayout="autoHeight"
          overlayNoRowsTemplate={"Gösterilecek SGK tahhakuku yok"}
        />
      </div>
    </Grid>
  );
};

export default SgkListModal;
