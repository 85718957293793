import React, { useState, useMemo, useCallback, useEffect } from "react";

import Sidebar from "../../components/sideBar/sideBar.js";
import Navbar from "../../components/navbar/navbar.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Swal from "sweetalert2";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DateRangePicker from "../../components/DateRangePicker/index.js";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";
import { FileInfo } from "tabler-icons-react";
import { createColumnDefs } from "./columnDefs.js";
import localStorage from "local-storage";
import { stopSync } from "../../api/Sync/stopSync.js";
import { getSynchronisationList } from "../../api/Sync/SynchronisationList.js";
import { darken } from "polished";

import {
  Grid,
  Typography,
  Pagination,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import localeTextTr from "../../locale.tr.js";

import moment from "moment";

function GridPage() {
  const today = moment().format("YYYY-MM-DD");
  const monthAgo = moment().subtract(30, "days").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(monthAgo);
  const [endDate, setEndDate] = useState(today);

  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [id, setSyncid] = useState();
  const [lastScroll, setLastScroll] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);

  const [filters, setFilters] = useState({
    status: null,
    types: [],
    taxDeclared: [],
    DeclarationStatus: null,
  });

  useEffect(() => {
    const lastPage = localStorage.get("page");
    const lastPageSize = localStorage.get("pageSize");
    const sideBarOpen = localStorage.get("sidebar");
    search();
    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    if (lastPage) {
      setCurrentPage(lastPage);
    }
    if (lastPageSize) {
      setPageSize(lastPageSize);
    }
    const localScroll = localStorage.get("scrollPosition");
    if (localScroll) {
      setLastScroll(localScroll);
    }

    const cleanupLocalStorage = () => {
      localStorage.clear();
    };
    window.addEventListener("beforeunload", cleanupLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", cleanupLocalStorage);
    };
  }, [pageSize, pageNumber]);
  function clearLocalStorage() {
    localStorage.clear();
  }
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    }),
    []
  );

  const ButtonRenderer = (props) => {
    const handleClick = () => {
      console.log(props.data);
      handleOpen();
      localStorage.set("scrollPosition", Math.floor(window.scrollY));
      // Buraya tıklama durumunda yapılacak işlemi ekleyin
    };

    return (
      <Button onClick={handleClick}>
        <FileInfo></FileInfo>
      </Button>
    );
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState(
    createColumnDefs(ButtonRenderer)
  );

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        defaultExpanded: false,
        enableRangeSelection: false,
        columnDefs: [
          {
            field: "createdAt",
            headerName: "Bitiş Tarihi",
            maxWidth: 170,
            cellStyle: {
              "white-space": "nowrap",
              overflow: "hidden",
            },
            cellRenderer: (params) => {
              if (params.value) {
                const date = new Date(params.value);
                return date
                  .toLocaleString("tr-TR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })
                  .replace(",", "");
              }
              return "";
            },
          },
          {
            field: "description",
            headerName: "Bilgi",
            maxWidth: 5000,
            cellStyle: {
              "white-space": "nowrap",
              overflow: "hidden",
            },
          },
        ],
        defaultColDef: {
          flex: 4,
          resizable: true,
          floatingFilter: false,
          suppressMenu: false, // Detay grid sütunlarında menüye izin ver
        },
      },
      getDetailRowData: (params) => {
        const detailData = params.data.subSyncs;
        params.successCallback(detailData);
      },
    };
  }, []);

  const onGridReady = useCallback((params) => {
    setRowData(data);
  }, []);

  const SyncStop = () => {
    Swal.fire({
      text: "Senkronizasyon İptal Edilecek. Devam etmek istiyor musunuz?",
      icon: "question",
      showDenyButton: true,
      denyButtonText: "Hayır, devam etme",
      confirmButtonText: "Evet, devam et",
      customClass: { htmlContainer: ".swal2-container" },
    }).then((result) => {
      if (result.isConfirmed) {
        stopSync(id)
          .then(() => {
            console.log("Senkronizasyon iptal ediliyor...");
            // Tabloyu güncellemek için search fonksiyonunu çağırın
            search();
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              text:
                err.response?.data?.message ||
                "İptal işlemi sırasında bir hata oluştu.",
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      } else if (result.isDismissed) {
        // Kullanıcı Hayır butonuna veya dışarı tıkladı
        console.log("Senkronizasyon iptal edilmedi.");
      }
    });
  };

  const search = () => {
    let newFilters = {
      ...filters,
      status:
        filters.status === "Tamamlandı"
          ? "Completed"
          : filters.status === "Devam ediyor"
          ? "Waiting"
          : filters.status === "Hatalı"
          ? "Failed"
          : filters.status === "İptal"
          ? "Cancel"
          : null,
    };

    getSynchronisationList(
      pageSize,
      pageNumber,
      startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      newFilters
    )
      .then((result) => {
        if (result?.data?.getSync) {
          // Tarihleri istenilen formata göre formatlayarak veriyi güncelle
          const formattedData = result.data.getSync.map((item) => ({
            ...item,
            updatedAt: item.updatedAt
              ? moment(item.updatedAt).format("DD-MM-YYYY HH:mm")
              : null,
            createdAt: item.createdAt
              ? moment(item.createdAt).format("DD-MM-YYYY HH:mm")
              : null,
            subSyncs:
              item.subSyncs?.map((subSync) => ({
                ...subSync,
                updatedAt: subSync.updatedAt
                  ? moment(subSync.updatedAt).format("YYYY-MM-DD HH:mm")
                  : null,
              })) || [],
          }));

          setData(formattedData); // Güncellenmiş veriyi ayarla
          setTotalCount(result.data.totalSyncsCount || 0); // Eğer değer null ise 0 kullan
          setTotalPage(result.data.totalPage || 0);
          setSyncid(result.data.getSync[0]?.id || null); // Eğer id null ise null ayarla
        }
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          text: "Sync hatası oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        });
      });
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease", // Düzeltilmiş transition
        }}
      >
        <Sidebar status={isOpen} toggleSidebar={toggleSidebar} />
      </Grid>

      <Grid
        item
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid item md={12} sx={{ mt: 1 }}>
            {/* <Alert
              severity="info"
              sx={{
                borderRadius: "2vh",
                border: "1px solid #1232e4",
                mb: 1,
              }}
            >
              Bilgilendirme Alanı
            </Alert> */}
          </Grid>
          <Grid
            className="grid-area"
            sx={{
              mb: 1,
            }}
          >
            <Grid
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "space-between",
              }}
            >
              <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="tr"
                >
                  <Grid xs={12} sx={{ display: "flex", gap: 1 }}>
                    <DateRangePicker
                      setStartDate={setStartDate}
                      startDate={startDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      isLimitedRangeForMonth={true}
                      s
                    />

                    <Button
                      sx={{
                        textTransform: "none",
                        alignItems: "center",
                        gap: 1,
                        marginLeft: "1vw",

                        backgroundColor: "#00cfe8",
                        "&:hover": {
                          backgroundColor: darken(0.1, "#00cfe8"),
                          borderColor: "#d9534f",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      onClick={() => search()}
                    >
                      <SearchIcon color="white" />
                      Ara
                    </Button>

                    <Grid item sx={{ marginLeft: "auto" }}>
                      <Button
                        variant="contained"
                        sx={{
                          gap: 1,
                          textTransform: "none", // Sadece istediğiniz harflerin büyük olmasını sağlar
                          backgroundColor: "red",
                        }}
                        onClick={SyncStop}
                        disabled={
                          data[0]?.status !== "Waiting" &&
                          data[0]?.status !== "BeingCanceled"
                        }
                      >
                        <CancelIcon color="white" />
                        Senkronizasyonu iptal Et
                      </Button>
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className="ag-theme-quartz"
            style={{
              minHeight: "67vh",

              width: "100%",
              borderRadius: "10px", // İstediğiniz değeri buraya ekleyin
              overflow: "hidden", // Border-radius'in düzgün görünmesi için
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          >
            <AgGridReact
              localeText={localeTextTr}
              rowData={data}
              columnDefs={columnDefs}
              enableRangeSelection={true}
              sideBar={gridSideBar}
              copyHeadersToClipboard={true}
              defaultColDef={defaultColDef}
              masterDetail={true}
              detailCellRendererParams={detailCellRendererParams}
              onGridReady={onGridReady}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "center",
              display: data?.length === 0 ? "none" : "flex",
              margin: "2% 0 2% 0",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPage}
              showFirstButton
              showLastButton
              page={pageNumber}
              onChange={(event, value) => setPageNumber(value)}
            />
            <TextField
              select
              value={pageSize}
              size="small"
              sx={{
                display: data?.length === 0 ? "none" : "block",
              }}
              onChange={(e) => {
                setPageNumber(1);
                setPageSize(e.target.value);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </TextField>
            <Typography sx={{ position: "absolute", right: "1%" }}>
              Toplam Kayıt : {totalCount}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GridPage;
