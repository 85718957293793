import {
  Grid,
  TextField,
  Typography,
  Button,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import TaxIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import Swal from "sweetalert2";
import { getTaxTypes } from "../../api/taxTypes/getList";
import { getRemoveTax } from "../../api/taxes/removeTax";

const RemoveTaxModal = ({
  setRemoveTaxModalOpen,
  selectedTaxPayersData,
  setSelectedTaxPayersData,
  search,
}) => {
  const [dataa, setDataa] = useState({
    title: "",
    taxes: [],
    people: [],
  });
  const [types, setTypes] = useState([]);

  useEffect(() => {
    getTaxTypes()
      .then((resp) => {
        setTypes(resp.data.data);
      })
      .catch((err) =>
        Swal.fire({
          text: "Vergi türleri alınırken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        })
      );
  }, []);

  const handleDelete = async () => {
    const Mükellefler = selectedTaxPayersData
      .filter((person) => !dataa.people.some((p) => p.id === person.id))
      .map((person) => String(person.id));

    const selectedTaxes = types
      .filter((type) => dataa.taxes.includes(type.value))
      .map((value) => value.value);

    const data = {
      taxPayer: Mükellefler,
      taxes: selectedTaxes,
    };

    await getRemoveTax(data).then((resp) => {
      if (resp) {
        Swal.fire({
          text: "Seçilen Mükelleflerden Vergiler Başarıyla Silindi",
          icon: "success",
          confirmButtonText: "Tamam",
        }).then(() => {
          search();
          setRemoveTaxModalOpen(false);
        });
      }
    });
  };

  const handleTaxTypeChange = (event, newValue) => {
    setDataa((prevData) => ({
      ...prevData,
      taxes: newValue?.map((tax) => tax.value) || [],
    }));
  };

  const handleRemovePerson = (personId) => {
    setSelectedTaxPayersData((prevPeople) =>
      prevPeople.filter((person) => person.id !== personId)
    );
  };

  return (
    <Paper
      elevation={4}
      sx={{
        padding: 4,
        borderRadius: 2,
        backgroundColor: "#ffffff",
        boxShadow: 3,
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            align="center"
            sx={{
              color: "#d32f2f",
              fontWeight: "bold",
              mb: 2,
            }}
          >
            Toplu Vergi Silme
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ pr: 2 }}>
          <Typography variant="h6" sx={{ color: "#f44336", mb: 1 }}>
            <PersonIcon sx={{ mr: 1 }} /> Mükellefler
          </Typography>
          <Paper
            elevation={1}
            sx={{
              height: "400px",
              overflowY: "auto",
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              p: 2,
            }}
          >
            <List>
              {selectedTaxPayersData.map((person) => (
                <ListItem
                  key={person.id}
                  disablePadding
                  sx={{
                    mb: 1,
                    bgcolor: "#ffffff",
                    borderRadius: 1,
                    "&:hover": { bgcolor: "#ffebee" },
                    boxShadow: 1,
                  }}
                >
                  <ListItemText
                    primary={person.name}
                    sx={{ textAlign: "center" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="remove"
                      onClick={() => handleRemovePerson(person.id)}
                      sx={{ color: "#b71c1c", "&:hover": { color: "#d32f2f" } }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ pl: 5, gap: 1 }}>
          <Autocomplete
            multiple
            id="tax-type-autocomplete"
            options={types}
            getOptionLabel={(option) => option?.value}
            value={types.filter((type) => dataa.taxes.includes(type.value))}
            limitTags={3}
            size="small"
            onChange={handleTaxTypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vergi Türleri"
                variant="outlined"
                fullWidth
              />
            )}
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" sx={{ color: "#757575" }}>
            <TaxIcon sx={{ verticalAlign: "middle", mr: 1 }} />
            Silmek istediğiniz vergileri seçiniz.
          </Typography>
          <Button
            sx={{
              mt: 2,
              width: "100%",
              height: "50px",
              textTransform: "none",
              bgcolor: "#f44336",
              color: "#ffffff",
              "&:hover": { bgcolor: "#d32f2f" },
              "&:disabled": {
                bgcolor: "#ffcdd2",
                color: "#ffffff",
              },
            }}
            variant="contained"
            onClick={handleDelete}
            startIcon={<DeleteIcon />}
            disabled={
              selectedTaxPayersData.length === 0 || dataa.taxes.length === 0
            }
          >
            Sil
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RemoveTaxModal;
