import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import { getTaxPayerListDetails } from "../../api/taxPayer/getTaxPayerListDetails";
import { updateTaxPayersPassword } from "../../api/taxPayer/updateTaxPayersPassword";
import { showLoadingSwal } from "../../components/loadingSwal";
import withReactContent from "sweetalert2-react-content";
import { getPasswordsFromAccountingProgram } from "../../api/taxPayer/getPasswordsFromAccountingProgram";
const MySwal = withReactContent(Swal);

const AddTaxModal = ({ selectedTaxPayersIds, setAddTaxModalOpen }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTaxPayerListDetails(selectedTaxPayersIds)
      .then((resp) => {
        setUsers(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          text:
            err?.response?.data?.message ||
            "Mükkelef bilgileri alınırken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        });
        setAddTaxModalOpen(false);
        setLoading(false);
      });
  }, [selectedTaxPayersIds]);

  const handleInputChange = (index, event) => {
    const newUsers = [...users];
    const newPassword = event.target.value;

    // ivd_credentials[0] her zaman var, sadece ivd_password güncellenecek
    if (Array.isArray(newUsers[index].ivd_credentials)) {
      newUsers[index].ivd_credentials[0] = {
        ...newUsers[index].ivd_credentials[0],
        ivd_password: newPassword,
      };
    }

    setUsers(newUsers);
  };

  const handleGetPasswordFromLuca = () => {
    MySwal.fire({
      title: "Luca bilgilerinizi giriniz.",
      html: (
        <div>
          <TextField
            id="customerCode"
            label="Üye Numarası"
            size="small"
            fullWidth
            style={{ marginBottom: "16px" }}
          />
          <TextField
            id="username"
            label="Kullanıcı Adı"
            size="small"
            fullWidth
            style={{ marginBottom: "16px" }}
          />
          <TextField
            id="password"
            label="Şifre"
            type="password"
            size="small"
            fullWidth
          />
          <Typography
            variant="caption"
            gutterBottom
            sx={{ display: "block", marginTop: 2 }}
          >
            *Luca bilgileriniz <b>kaydedilmemektedir</b>.
          </Typography>
        </div>
      ),
      showCancelButton: true,
      confirmButtonText: "Gönder",
      cancelButtonText: "İptal",
      preConfirm: () => {
        const customerCode = document.getElementById("customerCode").value;
        const username = document.getElementById("username").value;
        const password = document.getElementById("password").value;

        if (!customerCode || !username || !password) {
          Swal.showValidationMessage("Lütfen tüm alanları doldurun");
        }

        return { customerCode, username, password };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        showLoadingSwal("Bilgileriniz doğrulanıyor");

        getPasswordsFromAccountingProgram(result.value)
          .then((resp) => {
            console.log(resp.data);
            const updatedUsers = users.map((user) => {
              // Null kontrolleri
              if (!user.ivd_credentials || !user.vkn) {
                return user;
              }

              // Eşleşen şifreyi bul
              const matchingPassword = resp.data?.find(
                (p) =>
                  String(p.vkn) === String(user.vkn) ||
                  String(p.tckn) === String(user.tckn)
              );
              // Eşleşme yoksa orijinal kullanıcıyı döndür
              if (!matchingPassword) {
                return user;
              }
              // Yeni bir nesne oluştur ve şifreyi güncelle
              return {
                ...user,
                ivd_credentials: user.ivd_credentials.map((cred) => ({
                  ...cred,
                  ivd_password: matchingPassword.password,
                })),
              };
            });
            setUsers(updatedUsers);
            Swal.fire({
              text: `Şifreler başarıyla getirildi. Kaydet butonuna basmayı unutmayınız.`,
              icon: "success",
              confirmButtonText: "Tamam",
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              text:
                error?.response?.data?.message ||
                "Şifreler güncellenirken hata oluştu.",
              icon: "error",
              confirmButtonText: "Tamam",
              customClass: {
                container: ".swal-overlay",
              },
            });
          });
      }
    });
  };

  const handleSave = () => {
    // showLoadingSwal()
    let credentialsList = [];
    users.forEach((user) => {
      if (
        Array.isArray(user.ivd_credentials) && // user.ivd_credentials bir dizi mi?
        user.ivd_credentials.length > 0 // dizi boş değil mi?
      ) {
        credentialsList.push({
          vkn: user.vkn,
          newPassword: user.ivd_credentials[0].ivd_password||null,
        });
      }
    });

    updateTaxPayersPassword(credentialsList)
      .then((resp) => {
        Swal.fire({
          text: `${resp.data.message}`,
          icon: "success",
          confirmButtonText: "Tamam",
        });
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          text:
            err?.response?.data?.message ||
            "Mükkelef bilgileri kaydedilirken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        });
        setAddTaxModalOpen(false);
        setLoading(false);
      });
  };

  const toggleShowPassword = (index) => {
    const newUsers = [...users];
    newUsers[index].showPassword = !newUsers[index].showPassword;
    setUsers(newUsers);
  };

  const [showStatus, setShowStatus] = useState(false);

  const turnShowAllPasswords = (show) => {
    const newUsers = users.map((user) => ({
      ...user,
      showPassword: !show,
    }));
    setUsers(newUsers);
  };

  return (
    <>
      {loading && (
        <Grid
          container
          spacing={2}
          sx={{
            height: "75vh",
            width: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <CircularProgress size={40} thickness={4} />
            <Typography variant="body1" sx={{ mt: 2, color: "text.secondary" }}>
              Yükleniyor...
            </Typography>
          </Grid>
        </Grid>
      )}
      {!loading && (
        <Grid container sx={{ alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h5" gutterBottom>
              Mükellef Şifre Güncelleme
            </Typography>

            <Grid item sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleGetPasswordFromLuca}
                style={{ marginBottom: "16px", textTransform: "none" }}
              >
                Şifreleri muhasebe programından getir
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  turnShowAllPasswords(showStatus);
                  setShowStatus(!showStatus);
                }}
                style={{ marginBottom: "16px", textTransform: "none" }}
              >
                {users &&
                users.length > 0 &&
                users?.some((user) => user.showPassword)
                  ? "Tüm Şifreleri Gizle"
                  : "Tüm Şifreleri Göster"}
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              border: "1px inset black",
              pl: 1,
              pr: 1,
              mb: 2,
              overflowY: "auto",
              maxHeight: "75vh",
            }}
          >
            {users &&
              users.length > 0 &&
              users?.map((user, index) => (
                <React.Fragment key={user.id}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={8}>
                      <Typography variant="body1">{user?.title}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        type={user.showPassword ? "text" : "password"}
                        label="Şifre"
                        variant="outlined"
                        size="small"
                        value={
                          Array.isArray(user.ivd_credentials)
                            ? user.ivd_credentials[0]?.ivd_password ?? ""
                            : ""
                        }
                        onChange={(event) => handleInputChange(index, event)}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <Button
                              onClick={() => toggleShowPassword(index)}
                              style={{ marginLeft: "8px" }}
                            >
                              {user.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </Button>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  {index < users?.length - 1 && (
                    <Grid item xs={12}>
                      <Divider sx={{ backgroundColor: "#060647" }} />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              onClick={handleSave}
            >
              Kaydet
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AddTaxModal;
