/** @format */

import React, { useState, useEffect } from "react";
import { Typography, DialogContent, Dialog, DialogTitle } from "@mui/material";
import QRCode from "react-qr-code";

const QRModal = ({
    message,
    QRModalOpen,
    setQRModalOpen,
    handleSend,
    QR,
    process,
}) => {
    const [countdown, setCountdown] = useState(50);
    let countdownInterval;
    useEffect(() => {
        if (QRModalOpen) {
            countdownInterval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 1) {
                        clearInterval(countdownInterval);
                        setQRModalOpen(false);
                        setCountdown(50);
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(countdownInterval);
        };
    }, [QRModalOpen, setQRModalOpen]);

    return (
        <Dialog
            open={QRModalOpen}
            onClose={() => {
                setQRModalOpen(false);
                handleSend && handleSend();
                clearInterval(countdownInterval);
                setCountdown(50);
            }}
            maxWidth="sm"
        >
            <DialogTitle sx={{ textAlign: "center" }}>
                {message}{" "}
                <Typography
                    sx={{
                        color: "red",
                        fontWeight: "700",
                        textAlign: "center",
                    }}
                >
                    {process === "sync"
                        ? "QR kodu okuttuktan sonra senkronizayon işlemini tekrar başlatın"
                        : process === "send"
                        ? "QR kodu okuttuktan sonra tahakkukları tekrar gönderin"
                        : ""}
                </Typography>{" "}
            </DialogTitle>
            <DialogContent
                sx={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <QRCode
                    size={256}
                    style={{
                        width: "fit-content",
                        height: "fit-content",
                    }}
                    value={QR}
                    viewBox={`0 0 256 256`}
                />
                <Typography
                    sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        width: "100%",
                    }}
                >
                    Kalan süre: {countdown} saniye
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default QRModal;
