/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const getAccrualPdf = async (DriveID) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    return await axios.get(
        `${process.env.REACT_APP_API_URL}/declaration/get-accrual-pdf?DriveID=${DriveID}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
