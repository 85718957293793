/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const handleUnDeclaratedTaxes = async (
    taxPayerId,
    taxPeriod,
    Process,
    tax,
    note
) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    return await axios.post(
        `${process.env.REACT_APP_API_URL}/tax/handleUnDeclaratedTaxes`,
        {
            taxPayerId,
            taxPeriod,
            Process: Process,
            tax,
            note,
        },

        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
