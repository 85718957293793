/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const getUserList = async (
    pageNumber,
    pageSize,
    searchedValue,
    sort,
    filter,
    startDate,
    endDate
) => {
    const token = await CheckToken();

    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    return await axios.get(
        `${process.env.REACT_APP_API_URL}/users/getUsers`,

        {
            params: {
                pageNumber,
                pageSize,
                searchedValue,
                sort,
                filter,
                startDate,
                endDate,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
