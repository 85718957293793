/** @format */

import { Grid, TextField, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "../.././swall.css";
import { darken } from "polished";
import { getUser } from "../../api/user/getUser";
import { SyncIVD } from "../../api/ivd/sync";
import DateRangePicker from "../../components/DateRangePicker";
import date from "date-and-time";
import QRModal from "../../components/QRModal";

const SyncModal = ({ setSyncModalOpen}) => {
  const currentDate = new Date();
  const today = date.format(currentDate, "YYYY-MM-DD");
  const weekAgo = date.addDays(currentDate, -7);
  const [startDate, setStartDate] = useState(weekAgo);
  const [endDate, setEndDate] = useState(today);
  const [userData, setUserData] = useState({ vkn: " ", ivd_password: " " });
  const [userOData, setUserOData] = useState({ vkn: " ", ivd_password: " " });
  const [QR, setQR] = useState(null);
  const [message, setMessage] = useState(null);
  const [QRModalOpen, setQRModalOpen] = useState(false);
  const [userDK, setUserDk] = useState(null);
  const [base64, setBase64] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [process, setProcess] = useState("");

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getUser().then((resp) => {
      setUserData(resp.data);
      setUserOData(resp.data);
    });
  }, []);

  const sync = () => {
    setProcess("sync");
    SyncIVD(
      date.format(new Date(startDate), "YYYYMMDD"),
      date.format(new Date(endDate), "YYYYMMDD")
    )
      .then(async (resp) => {
        if (resp?.data?.status === true) {
          setSyncModalOpen(false);
          Swal.fire({
            text: resp?.data?.message,
            icon: "success",
            confirmButtonText: "Tamam",
            customClass: { htmlContainer: ".swal2-container" },
          });
        } else if (resp.data?.status === 2) {
          setBase64(resp?.data?.base64);
          setImageId(resp?.data?.imageId);
        } else if (resp.data?.status === 3) {
          setBase64(resp?.data?.base64);
          setImageId(resp?.data?.imageId);
          Swal.fire({
            text: resp?.data?.message,
            icon: "warning",
            confirmButtonText: "Tamam",
            customClass: { htmlContainer: ".swal2-container" },
          });
        }
        setUserDk("");
      })
      .catch(async (err) => {
        setUserDk("");
        // console.log(err.message, "err");
        if (
          err?.response?.status === 401 &&
          err?.response?.data?.status === "W401"
        ) {
          setQR(err?.response?.data?.data);
          setMessage(err?.response?.data?.message);
          setQRModalOpen(true);
        } else if (err?.code === "ERR_NETWORK") {
          Swal.fire({
            title: "Rahat Proxy'e Bağlanılamadı.",
            text: "Rahat Proxy kapalı ise lütfen başlatınız. Kurulu değilse butona tıklayarak indirip, yükleyebilirsiniz.",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "Rahat Proxy İndir",
            cancelButtonText: "İptal",
            customClass: { htmlContainer: ".swal2-container" },
            preConfirm: () => {
              try {
                window.location.href = "/downloads/RahatProxy.rar";
              } catch (error) {
                Swal.fire({
                  icon: "error",
                  title: "Dosya İndirilemedi!",
                  text: "Dosya indirme işlemi sırasında bir hata oluştu.",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        } else if (err?.message === "Error request server") {
          Swal.fire({
            text:
              err?.response?.data?.message ||
              "Sunucuyla bağlantı kurulamadı. Lütfen sistem yöneticinizle görüşünüz.",
            icon: "warning",
            confirmButtonText: "Tamam",
            customClass: { htmlContainer: ".swal2-container" },
          });
        } else {
          Swal.fire({
            text: err?.response?.data?.message || "Hata oluştu",
            icon: "warning",
            confirmButtonText: "Tamam",
            customClass: { htmlContainer: ".swal2-container" },
          });
        }
      });
  };

  const handleSync = () => {
    if (
      userData?.vkn !== userOData?.vkn ||
      userData?.ivd_password !== userOData?.ivd_password
    ) {
      Swal.fire({
        text: "İVD giriş bilgilerinizi değiştirdiniz. Kaydetmek istiyor musunuz?",
        icon: "question",
        showDenyButton: true,
        denyButtonText: "Hayır, kaydetme",
        confirmButtonText: "Evet, kaydet",
        customClass: { htmlContainer: ".swal2-container" },
      }).then((resp) => {
        if (resp.isConfirmed) {
          sync();
        }
      });
    } else {
      sync();
    }
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "40vh",
        borderRadius: "10px",
        padding: "2rem",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          align="center"
          fontWeight="bold"
          sx={{ color: "#003366" }}
        >
          SENKRONİZE ET
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <TextField
            value={userData?.vkn}
            label="VKN"
            sx={{
              width: "100%",
            }}
            name="vkn"
            size="medium"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={userData?.ivd_password}
            label="İVD Şifresi"
            sx={{
              width: "100%",
            }}
            name="ivd_password"
            size="medium"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <DateRangePicker
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          endDate={endDate}
          startDate={startDate}
          isLimitedRangeForMonth={true}
        />
      </Grid>
      {base64 && (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          direction="column"
          alignItems="center"
          sx={{ marginTop: "1rem" }}
        >
          <Typography>Lütfen aşağıdaki doğrulama kodunu giriniz:</Typography>
          <img
            src={`data:image/png;base64, ${base64}`}
            alt="Doğrulama Kodu"
            style={{ maxWidth: "100%", borderRadius: "8px", margin: "1rem 0" }}
          />
          <TextField
            value={userDK}
            onChange={(e) => setUserDk(e.target.value)}
            variant="outlined"
            label="Doğrulama Kodu"
            sx={{ width: "100%", maxWidth: "400px" }}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "1rem" }}
      >
        <Button
          onClick={handleSync}
          variant="contained"
          sx={{
            backgroundColor: "#1976d2",
            color: "#fff",
            "&:hover": {
              backgroundColor: darken(0.1, "#1976d2"),
            },
            marginRight: "1rem",
          }}
        >
          Senkronize Et
        </Button>
        <Button
          onClick={() => setSyncModalOpen(false)}
          variant="outlined"
          sx={{
            borderColor: "#1976d2",
            color: "#1976d2",
            "&:hover": {
              borderColor: darken(0.1, "#1976d2"),
              color: darken(0.1, "#1976d2"),
            },
          }}
        >
          Kapat
        </Button>
      </Grid>
      {QRModalOpen && (
        <QRModal
          QR={QR}
          message={message}
          QRModalOpen={QRModalOpen}
          setQRModalOpen={setQRModalOpen}
        />
      )}
    </Grid>
  );
};

export default SyncModal;
