/** @format */

import {
  Grid,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Button,
  MenuItem,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import "../../swall.css";
import SaveIcon from "@mui/icons-material/Save";
import { lighten, darken } from "polished";
import { PatternFormat } from "react-number-format";
import { createUser } from "../../api/user/createUser";

const CreateUserModal = ({
  setReload,
  reload,
  openCreateUserModal,
  setOpenCreateUserModal,
}) => {
  const [data, setData] = useState({
    title: "",
    email: "",
    password: "",
    vkn: "",
    ivd_password: "",
    number: [],
    ivd_credentials: {},
    status: true,
    sendToUserNotifications: true,
  });
  const [alertShow, setAlertShow] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.includes("ivd_credentials")) {
      const key = name.split(".")[1];
      setData((prevData) => ({
        ...prevData,
        ivd_credentials: {
          ...prevData.ivd_credentials,
          [key]: value,
        },
      }));
    } else if (name.includes("sgk")) {
      setData((prevData) => ({
        ...prevData,
        sgk_credentials: {
          ...prevData.sgk_credentials,
          [name.split(".")[1]]: value,
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const PatternFormatCustom = React.forwardRef(function PatternFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        key={props.key}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="### ### ## ##"
      />
    );
  });

  const handleSave = () => {
    const {
      title,
      vkn,
      email,
      number,
      password,
      ivd_password,
      status,
      ivd_credentials,
      sendToUserNotifications,
    } = data;
    const newDataToSend = {
      title,
      vkn,
      email,
      number: number.map((num) => num.replace(/\s/g, "")),
      ivd_credentials: ivd_credentials || {},
      password,
      status,
      ivd_password,
      sendToUserNotifications,
    };
    if (
      data?.vkn === "" ||
      data?.title === "" ||
      data?.email === "" ||
      data?.ivd_password === ""
    ) {
      setAlertShow(true);
      return;
    }
    Swal.fire({
      text: "Kullanıcı bilgileri ile yeni kullanıcı eklenecek.Devam etmek istiyor musunuz?",
      icon: "question",
      showDenyButton: true,
      denyButtonText: "Hayır, devam etme",
      confirmButtonText: "Evet, devam et",
      customClass: { htmlContainer: ".swal2-container" },
    }).then((result) => {
      if (result.isConfirmed) {
        createUser(newDataToSend)
          .then((resp) => {
            Swal.fire({
              text: resp.data?.message,
              icon: "success",
              confirmButtonText: "Tamam",
            });
            setOpenCreateUserModal(false);

            setReload(!reload);
          })
          .catch((err) => {
            if (err) {
              Swal.fire({
                text: err.response.data?.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            }
          });
      }
    });
  };

  const handleAddField = (fieldName) => {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: [...prevData[fieldName], ""],
    }));
  };

  const handleRemoveField = (fieldName, index) => {
    setData((prevData) => {
      const updatedValues = [...prevData[fieldName]];
      updatedValues.splice(index, 1);
      return {
        ...prevData,
        [fieldName]: updatedValues,
      };
    });
  };

  const handleChangeField = (fieldName, index, value) => {
    setData((prevData) => {
      const updatedValues = [...prevData[fieldName]];
      updatedValues[index] = value;
      return {
        ...prevData,
        [fieldName]: updatedValues,
      };
    });
  };

  return (
    <Dialog
      open={openCreateUserModal}
      onClose={() => setOpenCreateUserModal(false)}
      maxWidth="lg"
      PaperProps={{
        sx: {
          borderRadius: 4, // Köşe ovaliği eklemek için bu satırı ekleyin
        },
      }}
    >
      <DialogContent>
        <Grid
          container
          sx={{
            gap: 3,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Kullanıcı Ekleme
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField
              sx={{ width: "100%" }}
              value={data?.title}
              label="Ünvan"
              name="title"
              helperText={
                alertShow && data?.title === "" ? "Ünvan kısmı boş olamaz" : ""
              }
              error={alertShow && data?.title === ""}
              onChange={handleChange}
            />
          </Grid>{" "}
          <Grid item xs={5} sx={{ display: "flex", gap: 1 }}>
            <TextField
              sx={{ width: "100%" }}
              value={data?.vkn}
              label="VKN"
              name="vkn"
              helperText={
                alertShow && data?.vkn === "" ? "VKN kısmı boş olamaz" : ""
              }
              inputProps={{ maxLength: 10 }}
              error={alertShow && data?.vkn === ""}
              onChange={handleChange}
            />
            <TextField
              sx={{ width: "100%" }}
              value={data?.ivd_credentials?.tckn}
              label="TCKN"
              name="ivd_credentials.tckn"
              inputProps={{ maxLength: 11 }}
              onChange={handleChange}
            />
          </Grid>{" "}
          <Grid item xs={5}>
            <TextField
              sx={{ width: "100%" }}
              value={data?.email}
              label="E-posta"
              helperText={
                alertShow && data?.email === ""
                  ? "E-posta kısmı boş olamaz"
                  : ""
              }
              error={alertShow && data?.email === ""}
              name="email"
              onChange={(e) => handleChange(e)}
            />
          </Grid>{" "}
          <Grid item xs={5}>
            <TextField
              sx={{ width: "100%" }}
              value={data?.ivd_password}
              label="Dijital vergi dairesi şifresi"
              helperText={
                data?.ivd_password.length > 6
                  ? "Şifre 6 karakterden uzun"
                  : alertShow && data?.ivd_password === ""
                  ? "Dijital vergi dairesi şifresi kısmı boş olamaz"
                  : ""
              }
              FormHelperTextProps={{
                sx: {
                  color: data?.ivd_password.length > 6 ? "orange" : "",
                },
              }}
              error={alertShow && data?.ivd_password === ""}
              name="ivd_password"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              sx={{ width: "100%" }}
              value={data?.password}
              type="password"
              helperText={
                alertShow
                  ? data?.password === ""
                    ? "Şifre kısmı boş olamaz"
                    : data?.password.length < 8
                    ? "Şifre 8 karakterden az olamaz"
                    : !/[a-zA-Z]/.test(data?.password) ||
                      !/\d/.test(data?.password)
                    ? "Şifre karakter ve rakam içermek zorundadır"
                    : ""
                  : ""
              }
              error={
                (alertShow && data?.password === "") ||
                (alertShow && data?.password.length < 8) ||
                (alertShow && !/[a-zA-Z]/.test(data?.password)) ||
                (alertShow && !/\d/.test(data?.password))
              }
              label="Şifre"
              name="password"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              sx={{ width: "100%" }}
              value={data?.status}
              label="Kullanıcı durumu"
              name="status"
              onChange={handleChange}
              select
            >
              <MenuItem value={true}>Aktif</MenuItem>
              <MenuItem value={false}>Pasif</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              sx={{ width: "100%" }}
              value={data?.sendToUserNotifications}
              label="Tahakkuklar kime gönderilsin?"
              name="sendToUserNotifications"
              onChange={handleChange}
              select
            >
              <MenuItem value={true}>Müşavire</MenuItem>
              <MenuItem value={false}>Mükellefe</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <Typography
              sx={{
                flexDirection: "column",
                display: "flex",
                gap: 1,
              }}
            >
              {data?.number?.length < 2
                ? "Telefon numarası:"
                : "Telefon numaraları:"}

              {data?.number?.map((number, index) => (
                <div key={index}>
                  <TextField
                    sx={{ width: "80%", marginTop: "2%" }}
                    value={number
                      .toString()
                      .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4")}
                    key={index}
                    label={`Numara ${index + 1}`}
                    onBlur={(e) =>
                      handleChangeField("number", index, e.target.value)
                    }
                    InputProps={{
                      inputComponent: PatternFormatCustom,
                    }}
                  />
                  {index > 0 && (
                    <IconButton
                      onClick={() => handleRemoveField("number", index)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}
              <Tooltip title="Numara eklemek için tıklayın">
                <AddIcon
                  onClick={() => handleAddField("number")}
                  size="small"
                />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{
                textTransform: "none",
                color: darken(0.2, "#5cb85c"),
                backgroundColor: lighten(0.3, "#5cb85c"),
                "&:hover": {
                  backgroundColor: lighten(0.2, "#5cb85c"),
                  color: darken(0.3, "#5cb85c"),
                },
              }}
              variant="contained"
              onClick={() => handleSave()}
              startIcon={<SaveIcon sx={{ color: darken(0.2, "#5cb85c") }} />}
            >
              Kaydet
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;
