/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const createUser = async (data) => {
    console.log(data)

    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    return await axios.post(
        `${process.env.REACT_APP_API_URL}/users/create`,
        { ...data },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
