/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const getDeclarationList = async (
    id,
    pageNumber,
    pageSize,
    sort,
    filter,
    startDate,
    endDate
) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }

    return await axios.get(
        `${process.env.REACT_APP_API_URL}/declaration/get-list`,
        {
            params: {
                id,
                pageNumber,
                pageSize,
                sort,
                filter,
                startDate,
                endDate,
            },

            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
