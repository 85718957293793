/** @format */
import axios from "axios";
import CheckToken from "../../../src/utils/checkToken/CheckToken";
export const getReportList = async (
    pageSize,
    pageNumber,
    startDate,
    endDate,
    reportType
) => {
    const token = await CheckToken();
    if (!token) {
        // Redirect to login page
        window.location.href = "/login";
    }
    return await axios.get(
        `${process.env.REACT_APP_API_URL}/report/get-reports`,
        {
            params: {
                pageSize,
                pageNumber,
                startDate,
                endDate,
                reportType
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
