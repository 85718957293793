import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { lighten } from "polished";
import DownloadIcon from "@mui/icons-material/Download";

export const createColumnDefs = (handleClickButtonDownload) => [
  {
    headerName: "Durum",
    flex: 0,
    field: "status",
    headerCheckboxSelection: true,
    checkboxSelection: true,

    cellRenderer: (params) => {
      // Gelen veriye göre renk ve metin belirle

      let buttonText = "";
      if (params.data.status === "Completed") {
        buttonText = "Tamamlandı";
      } else if (params.data.status === "Failed") {
        buttonText = "Başarısız";
      } else {
        buttonText = "Bekleniyor";
      }

      // Buton oluştur
      return (
        <button
          sx={{
            textTransform: "none",
            mb: "3%",
          }}
          style={{
            backgroundColor:
              buttonText === "Tamamlandı"
                ? lighten(0.4, "#5cb85c")
                : buttonText === "Başarısız"
                ? lighten(0.2, "#d9534f")
                : lighten(0.3, "#f0ad4e"),
            color:
              buttonText === "Tamamlandı"
                ? "#12450f"
                : buttonText === "Başarısız"
                ? lighten(0.4, "#d9534f")
                : "#b3545c",
            padding: "6px 12px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            width: "130px", // Tüm butonların genişliği (örneğin 200 piksel)
            "&:hover": {
              backgroundColor: "#1565c0", // Hover rengi
            },
          }}
        >
          {buttonText}
        </button>
      );
    },

    filterParams: {
      valueFormatter: function (params) {
        console.log(params);
        if (params.value === "Completed") {
          return "Tamamlandı";
        } else if (params.value === "Failed") {
          return "Başarısız";
        } else {
          return "Bekleniyor";
        }
      },
    },
  },

  {
    field: "createdAt",
    flex: 1,
    headerName: "Rapor Tarihi",
    filter: "agDateColumnFilter",
    cellStyle: { textAlign: "start" },
    // checkboxSelection: true,
    // headerCheckboxSelection: true,
  },

  {
    field: "updatedAt",
    flex: 1,
    headerName: "Tamamlanma Zamanı",
    filter: "agDateColumnFilter",
    cellStyle: { textAlign: "start" },
    // checkboxSelection: true,
    // headerCheckboxSelection: true,
  },

  {
    field: "description",
    flex: 1,
    filter: false,
    headerName: "Bilgilendirme",
    cellStyle: { textAlign: "start" },
    // headerCheckboxSelection: true,
  },
  {
    headerName: "Rapor",
    field: "data",
    flex: 1,
    cellStyle: {
      textAlign: "start",
      alingItems: "center",
      display: "center",
      justifyContent: "center",
    },
    filter: false,
    cellRenderer: (params) => {
      const isDisabled = params.data.status !== "Completed"; // 'desiredStatus' yerine kontrol etmek istediğiniz durumu yazın

      return (
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            handleClickButtonDownload(params);
          }}
          sx={{
            textTransform: "none",
            mb: "2%",
          }}
          disabled={params.data.status !== "Completed"}
        >
          İndir
          <span style={{ marginRight: "10px" }} />{" "}
          {/* Boşluk eklemek için span */}
          <DownloadIcon />
        </Button>
      );
    },
  },
];
