/** @format */

import axios from "axios";
export const refreshToken = async (refreshToken) => {
    return await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/refresh-tokens`,
        {
            refreshToken,
        }
    );
};
