/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const getTaxPayerListDetails = async (ids) => {
  const token = await CheckToken();
  if (!token) {
    // Redirect to login page
    window.location.href = "/login";
  }

  return await axios.post(
    `${process.env.REACT_APP_API_URL}/tax-payer/get-taxpayers-detail-list`,
    {
      ids,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
